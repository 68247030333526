import axios from "axios";

export default {
  getList: (startDate, endDate) => {
    return axios.get(
      `/api/Orders/List?startDate=${startDate}&endDate=${endDate}`
    );
  },
  getRenewalDetails: (id) => {
    return axios.get("/api/Orders/RenewalDetails/" + id);
  },
  getActivationDetails: (id) => {
    return axios.get("/api/Orders/ActivationDetails/" + id);
  },
  approve: (order) => {
    return axios.post("/api/Orders/Approve/" + order.id);
  },
  reject: (order) => {
    return axios.post("/api/Orders/Reject/" + order.id);
  },
  delete: (order) => {
    return axios.delete("/api/Orders/" + order.id);
  },
};
