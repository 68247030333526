import LicenceService from "../../services/LicenceService";
import utils from "../../utils/helpers";

// initial state
const state = {
  list: [],
  loading: false,
};

// getters
const getters = {
  list: (state) => {
    return state.list;
  },
  isLoading: (state) => {
    return state.loading;
  },
};

// actions
const actions = {
  load: ({ commit, rootState }) => {
    commit("setLoading", true);
    var userId = rootState.route.params.userId;
    if (!userId) {
      userId = rootState.auth.userId;
    }
    return LicenceService.getList(userId)
      .then((result) => {
        var items = result.data.map((l) => {
          return utils.toCamelCase(l);
        });
        commit("setList", items);
        commit("app/setErrorLoading", false, {
          root: true,
        });

        return items;
      })
      .catch(() => {
        commit("setList", []);
        commit("app/setErrorLoading", true, {
          root: true,
        });
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  add: ({ commit }, licence) => {
    commit("setLoading", true);
    LicenceService.add(licence)
      .then((result) => {
        var items = result.data.map((l) => {
          return utils.toCamelCase(l);
        });
        commit("add", items);
        commit("app/setErrorOperation", false, {
          root: true,
        });
      })
      .catch(() => {
        commit("app/setErrorOperation", true, {
          root: true,
        });
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  edit: ({ commit }, item) => {
    commit("setLoading", true);
    LicenceService.edit(item)
      .then((response) => {
        var result = utils.toCamelCase(response.data);
        commit("edit", result);
        commit("app/setErrorOperation", false, {
          root: true,
        });
      })
      .catch(() => {
        commit("app/setErrorOperation", true, {
          root: true,
        });
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  editMulti: ({ commit }, params) => {
    commit("setLoading", true);
    LicenceService.editMulti(params)
      .then((response) => {
        var result = utils.toCamelCase(response.data);
        commit("deleteByIds", params.licences);
        commit("add", result);
        commit("app/setErrorOperation", false, {
          root: true,
        });
      })
      .catch(() => {
        commit("app/setErrorOperation", true, {
          root: true,
        });
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  delete: ({ commit }, item) => {
    commit("setLoading", true);
    LicenceService.delete(item.id)
      .then((response) => {
        commit("delete", item);
        if (response.data) {
          var result = utils.toCamelCase(response.data);
          commit("add", [result]);
        }
        commit("app/setErrorOperation", false, {
          root: true,
        });
      })
      .catch(() => {
        commit("app/setErrorOperation", true, {
          root: true,
        });
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  deleteMulti: ({ commit }, data) => {
    commit("setLoading", true);

    // create promises for each delete request
    var promises = data.items.map((item) => {
      return new Promise(function(resolve, reject) {
        var payload = { id: item.id, recursive: data.recursive };
        LicenceService.delete(payload)
          .then((response) => {
            commit("delete", item);
            if (response.data) {
              var result = utils.toCamelCase(response.data);
              commit("add", [result]);
            }

            resolve(item);
          })
          .catch(() => {
            reject();
          });
      });
    });

    // process the promises results
    Promise.all(promises)
      .then(function() {})
      .catch(function() {
        commit("app/setErrorOperation", true, {
          root: true,
        });
      })
      .finally(() => {
        commit("app/setErrorOperation", false, {
          root: true,
        });

        commit("setLoading", false);
      });
  },
  clear: ({ commit }) => {
    commit("CLEAR");
  },
  renew: ({ commit }, info) => {
    commit("setLoading", true);
    LicenceService.renew(info)
      .then((response) => {
        var result = utils.toCamelCase(response.data);
        commit("deleteByIds", info.licences);
        commit("add", result);
        commit("app/setErrorOperation", false, {
          root: true,
        });
      })
      .catch(() => {
        commit("app/setErrorOperation", true, {
          root: true,
        });
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  move: ({ commit }, info) => {
    commit("setLoading", true);
    LicenceService.move(info)
      .then((response) => {
        var result = utils.toCamelCase(response.data);
        commit("deleteByIds", info.licences);
        commit("add", result);
        commit("app/setErrorOperation", false, {
          root: true,
        });
      })
      .catch(() => {
        commit("app/setErrorOperation", true, {
          root: true,
        });
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  changeType: ({ commit }, info) => {
    commit("setLoading", true);
    LicenceService.changeType(info)
      .then((response) => {
        var result = utils.toCamelCase(response.data);
        commit("deleteByIds", info.licences);
        commit("add", result);
        commit("app/setErrorOperation", false, {
          root: true,
        });
      })
      .catch(() => {
        commit("app/setErrorOperation", true, {
          root: true,
        });
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  changeReseller: ({ commit }, info) => {
    commit("setLoading", true);
    LicenceService.changeReseller(info)
      .then((response) => {
        var result = utils.toCamelCase(response.data);
        commit("deleteByIds", info.licences);
        commit("add", result);
        commit("app/setErrorOperation", false, {
          root: true,
        });
      })
      .catch(() => {
        commit("app/setErrorOperation", true, {
          root: true,
        });
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  exportControlRoomFiles: ({ commit }, hardwareId) => {
    commit("setLoading", true);
    return LicenceService.controlRoomFiles(hardwareId)
      .then((response) => {
        // eslint-disable-next-line no-console
        return response;
      })
      .catch(() => {
        commit("app/setErrorOperation", true, {
          root: true,
        });
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
};

// mutations
const mutations = {
  setList(state, licences) {
    state.list = licences;
  },
  add(state, licences) {
    state.list = [...state.list, ...licences];
  },
  edit: (state, item) => {
    var index = state.list.findIndex((x) => x.id === item.id);
    if (index < 0) {
      return;
    }
    state.list.splice(index, 1, item);
  },
  delete: (state, item) => {
    var index = state.list.findIndex((x) => x.id === item.id);
    if (index < 0) {
      return;
    }
    state.list.splice(index, 1);
  },
  deleteByIds: (state, licences) => {
    licences.forEach((l) => {
      var index = state.list.findIndex((x) => x.id === l.licenceId);
      if (index >= 0) {
        state.list.splice(index, 1);
      }
    });
  },
  CLEAR: (state) => {
    state.list = [];
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
