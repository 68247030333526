import axios from 'axios'

export default {
	getList: () => {
		return axios.get("/api/Resellers/List");
	},
	get: id => {
		return axios.get("/api/Resellers/" + id);
	},
	add: reseller => {
		return axios.post("/api/Resellers", reseller);
	},
	edit: reseller => {
		return axios.put("/api/Resellers", reseller);
	},
	delete: id => {
		return axios.delete("/api/Resellers/" + id);
	}
}