import axios from 'axios'

export default {
	getList: () => {
		return axios.get("/api/Account/Users");
	},
	getPlatform: () => {
		return axios.get("/api/Account/Platforms");
	},
	add: user => {
		return axios.post("/api/Account/Register", user);
	},
}