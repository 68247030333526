import UserService from "../../services/UserService";
import utils from "../../utils/helpers";

// initial state
const state = {
  list: [],
  loading: false,
  platforms: [],
};

// getters
const getters = {
  list: (state) => {
    return state.list;
  },
  platforms: (state) => {
    return state.platforms;
  },
  isLoading: (state) => {
    return state.loading;
  },
};

// actions
const actions = {
  load: ({ commit }) => {
    commit("setLoading", true);
    return UserService.getList()
      .then((response) => {
        var items = response.data.map((l) => {
          return utils.toCamelCase(l);
        });
        commit("setList", items);
        commit("app/setErrorLoading", false, {
          root: true,
        });
        return items;
      })
      .catch(() => {
        commit("setList", []);
        commit("app/setErrorLoading", true, {
          root: true,
        });
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  platforms: ({ commit }) => {
    return UserService.getPlatform()
      .then((response) => {
        var result = utils.toCamelCase(response.data);
        commit("setPlatforms", result);
        commit("app/setErrorOperation", false, {
          root: true,
        });

        return result;
      })
      .catch(() => {
        commit("app/setErrorOperation", true, {
          root: true,
        });
      })
      .finally(() => {});
  },
  add: ({ commit }, item) => {
    commit("setLoading", true);
    UserService.add(item)
      .then((response) => {
        var result = utils.toCamelCase(response.data);
        commit("add", result);
        commit("app/setErrorOperation", false, {
          root: true,
        });
      })
      .catch(() => {
        commit("app/setErrorOperation", true, {
          root: true,
        });
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
};

// mutations
const mutations = {
  setList: (state, users) => {
    state.list = users;
  },
  setPlatforms: (state, platforms) => {
    state.platforms = platforms;
  },
  setLoading: (state, loading) => {
    state.loading = loading;
  },
  add: (state, item) => {
    state.list.push(item);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
