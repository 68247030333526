/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from "vue";
import Router from "vue-router";
import store from "../store";
import Meta from "vue-meta";
import axios from "axios";

// Routes
import paths from "./paths";

Vue.use(Router);

// Create a new router
const router = new Router({
  base: "/",
  mode: "history",
  routes: paths,

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
    return {
      x: 0,
      y: 0,
    };
  },
});

// Route guard checks to see if you are logged in, if not reroutes to login
// to is where you are going, matched.some is to find which routes have requiresAuth
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    var authorized = store.getters["auth/authorized"];
    if (!authorized) {
      axios.defaults.headers.common.Authorization = "";
      next("/login");
      return;
    }

    axios.defaults.headers.common["Authorization"] =
      "Bearer " + store.getters["auth/token"];
    if (!store.state.app.loaded) {
      next({ name: "loading", params: { returnTo: to } });
      return;
    }

    next();
  } else {
    next();
  }
});

Vue.use(Meta);

export default router;
