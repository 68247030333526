<template>
  <v-row>
    <v-col>
      <snackbar-error></snackbar-error>
      <v-data-table
        :headers="headers"
        :items="getLicencesPerUser"
        :items-per-page="10"
        sort-by="username"
        fixed-header
        height="61vh"
        dark
      >
        <template v-slot:top>
          <v-toolbar flat dark>
            <v-btn
              color="primary"
              class="mr-6"
              to="/dashboard/statistics/renewals"
            >
              Back
            </v-btn>
            <v-btn color="primary" class="mr-6">
              <download-csv separator-excel :data="getLicencesPerUser"
                >Export</download-csv
              >
            </v-btn>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import groupBy from "lodash/groupBy";
import filterLodash from "lodash/filter";
import capitalize from "lodash/capitalize";
import camelCase from "lodash/camelCase";

import costants from "../utils/costants";

export default {
  data() {
    return {
      month: null,
      year: null,
      licenceType: null,
      fontColor: "#B7B6B4",
      fontSize: 14,
      headers: [
        { text: "Name", value: "username" },
        { text: "Activity Logger", value: "activityLogger" },
        { text: "ArtWall", value: "artWall" },
        { text: "BrightSign", value: "brightSign" },
        { text: "BrightSign Pro", value: "brightSignPro" },
        { text: "PADS4", value: "pads4" },
        { text: "Queue Manager", value: "queueManager" },
        { text: "Sync Pool", value: "syncPool" },
        { text: "WallSign", value: "wallSign" },
        { text: "WallSign Pro", value: "wallSignPro" },
        { text: "Wallin ONE", value: "wallinOne" },
      ],
    };
  },
  mounted() {
    this.month = Number(this.$route.query.month);

    var month = capitalize(moment(this.month + 1, "MM").format("MMMM"));

    this.$store.dispatch("app/updateTitle", "Not renewed of " + month);
  },
  computed: {
    ...mapGetters({
      licences: "statistics/renewals",
    }),
    getLicencesPerUser: function() {
      if (!this.licences) {
        return [];
      }

      var filtered = this.licences[this.month + 1].filter((l) => {
        return l.renewalId === null;
      });

      var licencesPerUser = groupBy(filtered, "userId");

      var ret = [];

      Object.keys(licencesPerUser).forEach((key) => {
        if (licencesPerUser[key] && licencesPerUser[key].length > 0) {
          var row = {
            username: licencesPerUser[key][0].userName,
            userId: licencesPerUser[key][0].userId,
          };

          costants.LicencesTypes.forEach((type) => {
            var val = filterLodash(licencesPerUser[key], {
              licenceType: type.value,
            }).length;
            row[camelCase(type.text)] = val > 0 ? val : "-";
          });

          ret.push(row);
          // ret.push({
          //   username: licencesPerUser[key][0].userName,
          //   userId: licencesPerUser[key][0].userId,
          //   // activityLogger: filterLodash(licencesPerUser[key], {
          //   //   licenceType: 9
          //   // }).length,
          //   activityLogger: null,
          //   artWall: filterLodash(licencesPerUser[key], { licenceType: 6 })
          //     .length,
          //   brightSign: filterLodash(licencesPerUser[key], { licenceType: 1 })
          //     .length,
          //   brightSignPro: filterLodash(licencesPerUser[key], {
          //     licenceType: 2
          //   }).length,
          //   pads4: filterLodash(licencesPerUser[key], { licenceType: 3 })
          //     .length,
          //   queueManager: filterLodash(licencesPerUser[key], { licenceType: 8 })
          //     .length,
          //   syncPool: filterLodash(licencesPerUser[key], { licenceType: 5 })
          //     .length,
          //   wallSign: filterLodash(licencesPerUser[key], { licenceType: 4 })
          //     .length,
          //   wallSignPro: filterLodash(licencesPerUser[key], { licenceType: 7 })
          //     .length,
          //   wallinONE: filterLodash(licencesPerUser[key], { licenceType: 0 })
          //     .length
          // });
        }
      });

      return ret;
    },
  },
};
</script>

<style></style>
