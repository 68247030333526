export default {
  data() {
    return {
      windowSize: {
        x: 0,
        y: 0,
      },
      header: 48,
      contentPadding: 24,
      tableHeader: 64,
      tableFooter: 59,
      footer: 48,
      extra: 12,
    };
  },
  computed: {
    getContentHeight: function() {
      var height =
        this.windowSize.y -
        this.header -
        this.contentPadding -
        this.tableHeader -
        this.tableFooter -
        this.footer -
        this.extra;
      return height;
    },
  },
  methods: {
    onResize: function() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
  },
};
