<template>
  <v-row v-resize="onResize">
    <v-col>
      <snackbar-error></snackbar-error>
      <v-data-table
        :headers="headers"
        :items="sortedResellers"
        :items-per-page="10"
        :search="search"
        :custom-filter="customFilter"
        :loading="loading"
        :loading-text="loadingText"
        show-select
        v-model="selected"
        fixed-header
        :height="getContentHeight"
        show-expand
        dark
      >
        <template v-slot:top>
          <v-toolbar flat dark>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="mb-2" v-on="on">Add</v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.company"
                          label="Company"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="5" sm="5" md="5">
                        <v-text-field
                          v-model="newEmail"
                          label="Email"
                          :rules="[rules.required, rules.email]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="5" sm="5" md="5">
                        <v-text-field
                          v-model="newName"
                          label="Name"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-btn color="primary" text @click="addEmailName"
                          >Add</v-btn
                        >
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-simple-table dense height="150px">
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="item in editedItem.emails"
                                :key="item.email"
                              >
                                <td>
                                  <v-btn
                                    text
                                    icon
                                    color="red"
                                    @click="removeEmailName(item)"
                                  >
                                    <v-icon>mdi-window-close</v-icon>
                                  </v-btn>
                                </td>
                                <td>{{ item.email }}</td>
                                <td>{{ item.name }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="accent" text @click="close">Cancel</v-btn>
                  <v-btn color="primary" text @click="save">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-btn
              color="primary"
              dark
              class="mb-2 ml-2"
              @click="editItem()"
              v-show="selected.length == 1"
            >
              Edit
            </v-btn>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn
                  color="accent"
                  dark
                  class="ml-2 mb-2"
                  v-on="on"
                  v-show="selected.length > 0"
                  >Delete</v-btn
                >
              </template>
              <v-card>
                <v-card-title class="justify-center">
                  <span class="headline"
                    >Delete {{ selected.length }} resellers</span
                  >
                </v-card-title>

                <v-card-text class="pb-md-0">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12" class="pb-md-0 pt-md-0"
                        >Are you sure to delete the selected resellers?</v-col
                      >
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="accent" text @click="closeDeleteMulti"
                    >Cancel</v-btn
                  >
                  <v-btn color="primary" text @click="deleteMulti">OK</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-spacer />
            <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details
              clearable
              append-icon="mdi-magnify"
            ></v-text-field>
          </v-toolbar>
        </template>

        <template v-slot:[`item.startDate`]="{ item }">{{
          item.startDate | date
        }}</template>
        <template v-slot:[`item.endDate`]="{ item }">{{
          item.endDate | date
        }}</template>
        <template v-slot:[`item.lastUpdate`]="{ item }">{{
          item.lastUpdate | date
        }}</template>
        <template v-slot:[`item.licenceType`]="{ item }">{{
          item.licenceType | licenceType
        }}</template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-row>
              <v-col cols="6">
                Emails:
                <ul>
                  <li v-for="r in item.emails" v-bind:key="r.email">
                    {{ r.name }} — {{ r.email }}
                  </li>
                </ul>
              </v-col>
              <v-col cols="6">
                Users:
                <ul>
                  <li
                    v-for="r in getSortedUsers(item.users)"
                    v-bind:key="r.id"
                    v-on:click="goToLicences(r.id)"
                  >
                    <a>{{ r.name }}</a>
                  </li>
                </ul>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

import cloneDeep from "lodash/cloneDeep";
import sortBy from "lodash/sortBy";
import tableHeightMixin from "@/mixins/tableHeightMixin";

export default {
  mixins: [tableHeightMixin],
  data() {
    return {
      emailPattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = this.emailPattern;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "Id", value: "id" },
        { text: "Company", value: "company" },
      ],
      search: null,
      loadingText: "Loading...",
      editedIndex: -1,
      selected: [],
      editedItem: {
        company: null,
        emails: [],
      },
      defaultItem: {
        company: null,
        emails: [],
      },
      newEmail: "",
      newName: "",
    };
  },
  computed: {
    ...mapState({
      resellers: (state) => state.resellers.list,
      loading: (state) => state.resellers.loading,
      errorLoading: (state) => state.resellers.errorLoading,
      errorOperation: (state) => state.resellers.errorOperation,
    }),
    formTitle() {
      return this.editedIndex === -1 ? "New Reseller" : "Edit Reseller";
    },
    sortedResellers() {
      return sortBy(this.resellers, "company");
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    customFilter: (value, search, item) => {
      if (value === undefined || value === null || value === "") {
        return false;
      }

      search = search.toString().toLowerCase();

      if (
        value
          .toString()
          .toLowerCase()
          .indexOf(search) >= 0
      ) {
        return true;
      }

      return item.emails.some(
        (x) =>
          x.name
            .toString()
            .toLowerCase()
            .indexOf(search) >= 0 ||
          x.email
            .toString()
            .toLowerCase()
            .indexOf(search) >= 0
      );
    },

    addEmailName() {
      // email validation regex

      if (
        this.newEmail.length > 0 &&
        this.newName.length > 0 &&
        this.emailPattern.test(this.newEmail)
      ) {
        this.editedItem.emails.push({
          email: this.newEmail,
          name: this.newName,
        });
        this.newEmail = "";
        this.newName = "";
      }
    },
    removeEmailName(item) {
      const index = this.editedItem.emails.indexOf(item);
      this.editedItem.emails.splice(index, 1);
    },
    editItem() {
      var item = this.selected[0];
      this.newEmail = "";
      this.newName = "";
      this.editedIndex = this.resellers.indexOf(item);
      this.editedItem = cloneDeep(item);
      this.dialog = true;
    },
    closeDeleteMulti() {
      this.dialogDelete = false;
      this.selected = [];
    },
    deleteMulti() {
      this.$store.dispatch("resellers/deleteMulti", {
        items: this.selected,
      });
      this.closeDeleteMulti();
    },

    close() {
      this.dialog = false;
      this.newEmail = "";
      this.newName = "";

      //reset edited item
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        this.$store.dispatch("resellers/edit", this.editedItem);
      } else {
        this.$store.dispatch("resellers/add", this.editedItem);
      }
      this.close();
    },

    goToLicences: function(id) {
      this.$router.push("/dashboard/licences/" + id);
    },

    getSortedUsers(users) {
      return sortBy(users, "name");
    },
  },
  mounted() {
    this.$store.dispatch("app/updateTitle", "Resellers");
  },
};
</script>
