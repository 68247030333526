<template>
  <v-row>
    <v-col>
      <snackbar-error></snackbar-error>
      <v-data-table
        :headers="headers"
        :items="getLicencesPerUser"
        :items-per-page="10"
        sort-by="username"
        fixed-header
        height="61vh"
        dark
      >
        <template v-slot:top>
          <v-toolbar flat dark>
            <v-btn
              color="primary"
              class="mr-6"
              to="/dashboard/statistics/renewals"
            >
              Back
            </v-btn>
            <v-btn color="primary" class="mr-6">
              <download-csv separator-excel :data="getLicencesPerUser"
                >Export</download-csv
              >
            </v-btn>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import groupBy from "lodash/groupBy";
import capitalize from "lodash/capitalize";
import util from "../utils/helpers";

export default {
  data() {
    return {
      month: null,
      year: null,
      licenceType: null,
      fontColor: "#B7B6B4",
      fontSize: 14,
      headers: [
        { text: "Name", value: "username" },
        { text: "Not Renewed", value: "notRenewed" },
      ],
    };
  },
  mounted() {
    this.month = Number(this.$route.query.month);
    this.licenceType = Number(this.$route.query.type);

    var month = capitalize(moment(this.month + 1, "MM").format("MMMM"));

    this.$store.dispatch(
      "app/updateTitle",
      "Not renewed " + util.formatLicence(this.licenceType) + " of " + month
    );
  },
  computed: {
    ...mapGetters({
      licences: "statistics/renewals",
    }),
    getLicencesPerUser: function() {
      if (!this.licences) {
        return [];
      }

      var filtered = this.licences[this.month + 1].filter((l) => {
        return l.renewalId === null && l.licenceType === this.licenceType;
      });

      var licencesPerUser = groupBy(filtered, "userId");

      var ret = [];

      Object.keys(licencesPerUser).forEach((key) => {
        if (licencesPerUser[key] && licencesPerUser[key].length > 0) {
          ret.push({
            username: licencesPerUser[key][0].userName,
            userId: licencesPerUser[key][0].userId,
            notRenewed: licencesPerUser[key].length,
          });
        }
      });

      return ret;
    },
  },
};
</script>

<style></style>
