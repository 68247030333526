<template>
  <v-container>
    <v-row>
      <snackbar-error></snackbar-error>
      <v-col cols="12">
        <div class="d-flex justify-space-between">
          <v-text-field
            v-model="year"
            label="Select year"
            max="2100"
            min="2015"
            step="1"
            style="width: 125px"
            type="number"
            :loading="loading"
            @change="changeYear"
            class="flex-grow-0 flex-shrink-0"
          ></v-text-field>
          <v-toolbar flat class="flex-grow-0 flex-shrink-0">
            <v-toolbar-items>
              <v-btn text title>
                Total:
                <span class="headline primary--text ma-2">{{
                  getTotalExpyringLicences()
                }}</span>
              </v-btn>
              <v-btn text title>
                Renewed:
                <span class="headline primary--text ma-2">{{
                  getRenewedPercentage()
                }}</span>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </div>
      </v-col>
      <v-col cols="12">
        <v-tabs v-model="tab">
          <v-tab v-for="item in tabItems" :key="item">{{ item }}</v-tab>
        </v-tabs>
      </v-col>

      <!-- CALENDAR -->
      <v-col
        v-for="(item, x) in getCalendar()"
        :key="x"
        cols="12"
        sm="12"
        lg="4"
        md="4"
        xs="12"
        v-show="!loading && tab == 0"
      >
        <v-card>
          <div class="d-flex flex-no-wrap justify-space-between primary">
            <div>
              <v-card-title>
                <v-btn
                  class="title font-weight-light"
                  style="padding-left: unset"
                  text
                  @click="onMonthClick(x)"
                  >{{ item.month }}</v-btn
                >
              </v-card-title>
              <v-card-subtitle>Renewed licences/Total</v-card-subtitle>
            </div>
            <v-card-title class="title font-weight-light"
              >{{ item.renewed }}/{{ item.count }} [{{
                Math.round((item.renewed / item.count) * 100)
              }}%]</v-card-title
            >
          </div>
          <v-divider></v-divider>
          <v-card-text>
            <v-list>
              <v-list-item-group v-model="item.types" color="primary">
                <v-list-item
                  v-for="(type, i) in item.types"
                  :key="i"
                  @click="onClick(type.licenceType, x)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="type.name"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action
                    >{{ type.renewed }}/{{ type.count }}</v-list-item-action
                  >
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- CHARTS -->
      <v-row>
        <v-col cols="12" lg="6" md="6" v-show="!loading && tab == 1">
          <line-chart
            :chart-data="getLicencesLineChart()"
            :options="getLicencesLineChartOptions()"
          />
        </v-col>
        <v-col cols="12" lg="6" md="6" v-show="!loading && tab == 1">
          <pie-chart
            :chart-data="getLicencesPieChart()"
            :options="getLicencesPieChartOptions()"
          />
        </v-col>
        <v-col
          cols="12"
          lg="3"
          md="6"
          v-for="(item, i) in licenceTypes"
          :key="i"
          v-show="!loading && tab == 1"
        >
          <line-chart
            :chart-data="getLicencesLineChart(item.value)"
            :options="getLicencesLineChartOptions(item.value)"
          />
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import costants from "../utils/costants";
import moment from "moment";
import util from "../utils/helpers";
import cloneDeep from "lodash/cloneDeep";
import sortBy from "lodash/sortBy";
import filter from "lodash/filter";
import groupBy from "lodash/groupBy";

export default {
  name: "Dashboard",
  data() {
    return {
      licenceTypes: costants.LicencesTypes,
      year: moment().year(),
      tab: null,
      tabItems: ["Calendar", "Charts"],
      fontColor: "#B7B6B4",
      fontSize: 14,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.statistics.loading,
      // licences: (state) => state.statistics.renewals,
    }),
    ...mapGetters({
      licences: "statistics/renewals",
    }),
  },
  methods: {
    getMonth(index) {
      return moment(index, "MM")
        .format("MMMM")
        .toUpperCase();
    },
    onClick: function(type, month) {
      // eslint-disable-next-line no-console
      this.$router.push({
        path: "/dashboard/statistics/licences/notrenewals/monthly",
        query: { type: type, month: month },
      });
    },
    onMonthClick: function(month) {
      // eslint-disable-next-line no-console
      this.$router.push({
        path: "/dashboard/statistics/users/notrenewals/monthly",
        query: { month: month },
      });
    },
    getLicenceType(type) {
      return util.formatLicence(type);
    },
    changeYear() {
      this.$store.dispatch("statistics/renewals", this.year);
    },
    getLicencesPieChart() {
      var licencesTypes;

      // collapse licences from dictionary of month/licences
      if (this.licences !== undefined && this.licences !== null) {
        var i;
        var licencesArray = [];
        for (i = 0; i <= 11; i++) {
          if (this.licences[i + 1] !== undefined) {
            licencesArray.push(...this.licences[i + 1]);
          }
        }
        licencesTypes = groupBy(licencesArray, "licenceType");
      }

      // group licences per type
      var licenceMapPerTypes = [];
      var colors = costants.LicencesTypes.map(function(e) {
        return e.color;
      });
      costants.LicencesTypes.forEach(function(element) {
        licenceMapPerTypes[element.value] = 0;
        if (
          licencesTypes !== undefined &&
          licencesTypes[element.value] !== undefined
        ) {
          licenceMapPerTypes[element.value] = licencesTypes[element.value];
        }
      });

      return {
        datasets: [
          {
            data: licenceMapPerTypes.map(function(element) {
              return element.length;
            }),
            backgroundColor: colors,
          },
        ],

        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: costants.LicencesTypes.map(function(element) {
          return element.text;
        }),
      };
    },
    getLicencesPieChartOptions() {
      return {
        title: {
          display: true,
          text: "Total licences per type",
          fontColor: this.fontColor,
          fontSize: this.fontSize,
        },
        legend: {
          labels: {
            fontColor: this.fontColor,
            fontSize: this.fontSize,
          },
        },
      };
    },
    getLicencesLineChartOptions(licenceType) {
      var title = "Total licences / Renewed";
      if (licenceType !== undefined) {
        title = util.formatLicence(licenceType) + " " + title;
      }

      return {
        title: {
          display: true,
          text: title,
          fontColor: this.fontColor,
          fontSize: this.fontSize,
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                color: this.fontColor,
              },
              ticks: {
                fontColor: this.fontColor,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                drawOnChartArea: false,
                color: this.fontColor,
              },
              ticks: {
                fontColor: this.fontColor,
                fontSize: this.fontSize,
              },
            },
          ],
        },
        legend: {
          labels: {
            fontColor: this.fontColor,
            fontSize: this.fontSize,
          },
        },
      };
    },
    getLicencesLineChart(licenceType) {
      var chartData = {
        labels: [],
        datasets: [
          {
            label: "Total",
            pointBackgroundColor: "#2196f3",
            backgroundColor: "'rgba(255, 255, 255, 0.1)",
            fill: false,
            borderColor: "#2196f3",
            pointBorderColor: "white",
            data: [],
          },
          {
            label: "Renewed",
            pointBackgroundColor: "orange",
            borderColor: "orange",
            pointBorderColor: "white",
            fill: false,
            data: [],
          },
        ],
      };
      var i;
      var licencesInMonth = [];
      for (i = 0; i <= 11; i++) {
        // store month
        chartData.labels.push(this.getMonth(i + 1).substring(0, 3));

        var licencesCount = 0;
        var renewedCount = 0;
        if (this.licences != null && this.licences[i + 1] !== undefined) {
          // get the licence of the month (i+1)
          licencesInMonth = this.licences[i + 1];

          // filter by licenceType
          if (licenceType !== undefined) {
            licencesInMonth = filter(licencesInMonth, function(o) {
              return o.licenceType == licenceType;
            });
          }

          licencesCount = licencesInMonth.length;
          renewedCount = filter(licencesInMonth, function(o) {
            return o.renewalId !== null;
          }).length;
        }

        chartData.datasets[0].data.push(licencesCount);
        chartData.datasets[1].data.push(renewedCount);
      }

      return chartData;
    },
    getTotalExpyringLicences() {
      var months = this.getCalendar();
      var total = 0;
      var i;
      if (months.length > 0) {
        for (i = 0; i <= 11; i++) {
          total += months[i].count;
        }
      }

      return total;
    },
    getTotalRenewedLicences() {
      var months = this.getCalendar();
      var total = 0;
      var i;
      if (months.length > 0) {
        for (i = 0; i <= 11; i++) {
          total += months[i].renewed;
        }
      }

      return total;
    },
    getRenewedPercentage() {
      var ret = 0;
      var renewed = this.getTotalRenewedLicences();
      var expiring = this.getTotalExpyringLicences();

      if (expiring > 0) {
        ret = renewed / expiring;
      }
      return Math.round(ret * 100) + "%";
    },
    getCalendar() {
      var map = [];
      var i;

      // sort licenceTypes by text
      var licenceTypes = cloneDeep(costants.LicencesTypes);
      licenceTypes = sortBy(licenceTypes, "text");

      if (this.licences != null) {
        // process each month
        for (i = 0; i <= 11; i++) {
          var types = [];
          var licences = [];
          var licenceMapPerTypes = [];

          if (this.licences[i + 1] !== undefined) {
            // get the licence of the month (i+1)
            licences = this.licences[i + 1];

            // group month licences per licenceType
            licenceMapPerTypes = groupBy(licences, "licenceType");
          }
          // for each licence type create an object with the licences info
          licenceTypes.forEach(function(element, index) {
            // default object
            types[index] = {
              name: element.text,
              count: 0,
              renewed: 0,
              licenceType: element.value,
            };
            // fill the object about licences in this month
            if (licenceMapPerTypes[element.value] !== undefined) {
              types[index] = {
                name: element.text,
                count: licenceMapPerTypes[element.value].length,
                renewed: filter(licenceMapPerTypes[element.value], function(o) {
                  return o.renewalId !== null;
                }).length,
                licenceType: element.value,
              };
            }
          });

          map[i] = {
            month: this.getMonth(i + 1),
            count: licences.length,
            renewed: filter(licences, function(o) {
              return o.renewalId !== null;
            }).length,
            types: types,
          };
        }
      }
      return map;
    },
  },
  mounted() {
    this.$store.dispatch("app/updateTitle", "Renewed and expiring licences");
    this.year = this.$store.state.statistics.year;
    this.$store.dispatch("statistics/renewals", this.year);
  },
};
</script>
