<template>
  <v-row v-resize="onResize">
    <v-col>
      <snackbar-error></snackbar-error>
      <v-data-table
        ref="licencesDT"
        :headers="licencesHeaders"
        :items="getSortedLicences()"
        :items-per-page="10"
        :search="search"
        :custom-filter="customFilter"
        :loading="loading"
        :loading-text="loadingText"
        v-model="selected"
        show-select
        fixed-header
        :height="getContentHeight"
        show-expand
        dark
      >
        <template v-slot:top>
          <v-toolbar flat dark>
            <!-- add dialog -->
            <v-dialog v-model="dialog.add" max-width="500px">
              <template v-slot:activator="{}">
                <v-btn color="primary" dark @click="openAdd">Add</v-btn>
              </template>
              <v-card>
                <v-card-title class="justify-center pb-sm-0">
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text class="pb-md-0 pb-sm-0">
                  <v-container>
                    <v-row>
                      <v-col cols="6" class="pb-md-0 pt-md-0 pb-sm-0 pt-sm-0">
                        <v-select
                          v-model="editedItem.licenceType"
                          :items="licenceTypes"
                          item-text="text"
                          item-value="value"
                          label="Licence type"
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="6"
                        v-show="editedIndex === -1"
                        class="pb-md-0 pt-md-0 pb-sm-0 pt-sm-0"
                      >
                        <v-text-field
                          v-model="editedItem.quantity"
                          type="number"
                          label="Quantity"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" class="pb-md-0 pt-md-0 pb-sm-0 pt-sm-0">
                        <v-select
                          v-model="editedItem.resellerId"
                          :items="resellers"
                          item-text="company"
                          item-value="id"
                          label="Reseller"
                        ></v-select>
                      </v-col>
                      <v-col cols="6" class="pb-md-0 pt-md-0 pb-sm-0 pt-sm-0">
                        <v-text-field
                          v-model="editedItem.orderRef"
                          label="Order Ref"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="pb-md-0 pt-md-0 pb-sm-0 pt-sm-0">
                        <v-text-field
                          v-model="editedItem.hardwareId"
                          label="HardwareId"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="pb-md-0 pt-md-0 pb-sm-0 pt-sm-0">
                        <v-text-field
                          v-model="editedItem.backupHardwareId"
                          label="Backup hardwareId"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="pb-md-0 pt-md-0 pb-sm-0 pt-sm-0">
                        <v-text-field
                          v-model="editedItem.description"
                          label="Description"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" class="pb-md-0 pt-md-0 pb-sm-0 pt-sm-0">
                        <v-menu
                          ref="menuStart"
                          v-model="menuStartDate"
                          :close-on-content-click="false"
                          :return-value.sync="editedItem.startDate"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="formattedStartDate"
                              label="Start Date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.startDate"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="accent"
                              @click="menuStartDate = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              text
                              color="primary"
                              @click="
                                $refs.menuStart.save(editedItem.startDate)
                              "
                              >OK</v-btn
                            >
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="6" class="pb-md-0 pt-md-0 pb-sm-0 pt-sm-0">
                        <v-menu
                          ref="menuEnd"
                          v-model="menuEndDate"
                          :close-on-content-click="false"
                          :return-value.sync="editedItem.endDate"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="formattedEndDate"
                              label="End Date"
                              clearable
                              prepend-icon="mdi-calendar"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.endDate"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="accent"
                              @click="menuEndDate = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menuEnd.save(editedItem.endDate)"
                              >OK</v-btn
                            >
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="3" class="pb-md-0 pt-md-0 pb-sm-0 pt-sm-0">
                        <v-checkbox
                          v-model="editedItem.trial"
                          class="mx-2 mt-sm-0"
                          label="Trial"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="3" class="pb-md-0 pt-md-0 pb-sm-0 pt-sm-0">
                        <v-checkbox
                          v-model="editedItem.notForResale"
                          class="mx-2 mt-sm-0"
                          label="NFR"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="3" class="pb-md-0 pt-md-0 pb-sm-0 pt-sm-0">
                        <v-checkbox
                          v-model="editedItem.mirror"
                          class="mx-2 mt-sm-0"
                          label="Mirror"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="3" class="pb-md-0 pt-md-0 pb-sm-0 pt-sm-0">
                        <v-checkbox
                          v-model="editedItem.backup"
                          class="mx-2 mt-sm-0"
                          label="Backup"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="accent" text @click="closeAdd">Cancel</v-btn>
                  <v-btn color="primary" text @click="save">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- renew dialog -->
            <v-dialog v-model="dialog.renew" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  dark
                  class="ml-2"
                  v-on="on"
                  v-show="selected.length > 0"
                  >Renew</v-btn
                >
              </template>
              <v-card>
                <v-card-title class="justify-center">
                  <span class="headline"
                    >Renew {{ selected.length }} licences</span
                  >
                </v-card-title>

                <v-card-text class="pb-md-0">
                  <v-container>
                    <v-row>
                      <v-col cols="6" lg="12" class="pb-md-0 pt-md-0">
                        <v-menu
                          ref="menuRenewStart"
                          v-model="menuRenewStartDate"
                          :close-on-content-click="false"
                          :return-value.sync="renewMultiItem.startDate"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="formattedRenewStartDate"
                              label="Start Date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="renewMultiItem.startDate"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="accent"
                              @click="menuRenewStartDate = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              text
                              color="primary"
                              @click="
                                $refs.menuRenewStart.save(
                                  renewMultiItem.startDate
                                )
                              "
                              >OK</v-btn
                            >
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="6" lg="12" class="pb-md-0 pt-md-0">
                        <v-menu
                          ref="menuRenewEnd"
                          v-model="menuRenewEndDate"
                          :close-on-content-click="false"
                          :return-value.sync="renewMultiItem.endDate"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="formattedRenewEndDate"
                              label="End Date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="renewMultiItem.endDate"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="accent"
                              @click="menuRenewEndDate = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              text
                              color="primary"
                              @click="
                                $refs.menuRenewEnd.save(renewMultiItem.endDate)
                              "
                              >OK</v-btn
                            >
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="6" lg="12" class="pb-md-0 pt-md-0">
                        <v-text-field
                          v-model="renewMultiItem.orderRef"
                          label="Order Ref"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="accent" text @click="closeRenew">Cancel</v-btn>
                  <v-btn color="primary" text @click="renew">Renew</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialog.multiDelete" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn
                  color="accent"
                  dark
                  class="ml-2"
                  v-on="on"
                  v-show="selected.length > 0"
                  >Delete</v-btn
                >
              </template>
              <v-card>
                <v-card-title class="justify-center">
                  <span class="headline"
                    >Delete {{ selected.length }} licences</span
                  >
                </v-card-title>

                <v-card-text class="pb-md-0">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12" class="pb-md-0 pt-md-0"
                        >Are you sure to delete the select licences?</v-col
                      >
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="12" md="12" class="pb-md-0 pt-md-0">
                        <v-checkbox
                          v-model="dialog.recursiveDelete"
                          class="mt-sm-0"
                          label="Delete licences recursively"
                        ></v-checkbox
                      ></v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="accent" text @click="closeDeleteMulti"
                    >Cancel</v-btn
                  >
                  <v-btn color="primary" text @click="deleteMulti">OK</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-menu
              bottom
              v-model="actionMenu"
              offset-y
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  class="ml-2"
                  v-on="on"
                  v-show="selected.length > 0"
                  >Actions</v-btn
                >
              </template>
              <v-list>
                <!-- multiedit dialog -->
                <v-dialog v-model="dialog.multiEdit" max-width="500px">
                  <template v-slot:activator="{ on }">
                    <v-list-item
                      @click="() => {}"
                      v-on="on"
                      v-show="selected.length > 1"
                    >
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                  </template>
                  <v-card>
                    <v-card-title class="justify-center">
                      <span class="headline"
                        >Editing {{ selected.length }} licences</span
                      >
                    </v-card-title>

                    <v-card-text class="pb-md-0">
                      <v-container>
                        <v-row>
                          <v-col cols="6" sm="6" md="6" class="pb-md-0 pt-md-0">
                            <v-select
                              v-model="editMultiItem.licenceType"
                              :items="licenceTypes"
                              item-text="text"
                              item-value="value"
                              label="Licence type"
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6" class="pb-md-0 pt-md-0">
                            <v-select
                              v-model="editMultiItem.resellerId"
                              :items="resellers"
                              item-text="company"
                              item-value="id"
                              label="Reseller"
                            ></v-select>
                          </v-col>
                          <v-col cols="6" class="pb-md-0 pt-md-0">
                            <v-text-field
                              v-model="editMultiItem.orderRef"
                              label="Order Ref"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" class="pb-md-0 pt-md-0">
                            <v-text-field
                              v-model="editMultiItem.description"
                              label="Description"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" class="pb-md-0 pt-md-0">
                            <v-menu
                              ref="menuStartMultiEdit"
                              v-model="menuMultiEditStartDate"
                              :close-on-content-click="false"
                              :return-value.sync="editMultiItem.startDate"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="formattedMultiEditStartDate"
                                  label="Start Date"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editMultiItem.startDate"
                                no-title
                                scrollable
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="accent"
                                  @click="menuMultiEditStartDate = false"
                                  >Cancel</v-btn
                                >
                                <v-btn
                                  text
                                  color="primary"
                                  @click="
                                    $refs.menuStartMultiEdit.save(
                                      editedItem.startDate
                                    )
                                  "
                                  >OK</v-btn
                                >
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="6" class="pb-md-0 pt-md-0">
                            <v-menu
                              ref="menuEndMultiEdit"
                              v-model="menuMultiEditEndDate"
                              :close-on-content-click="false"
                              :return-value.sync="editMultiItem.endDate"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="formattedMultiEditEndDate"
                                  label="End Date"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editMultiItem.endDate"
                                no-title
                                scrollable
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="accent"
                                  @click="menuMultiEditEndDate = false"
                                  >Cancel</v-btn
                                >
                                <v-btn
                                  text
                                  color="primary"
                                  @click="
                                    $refs.menuEndMultiEdit.save(
                                      editMultiItem.endDate
                                    )
                                  "
                                  >OK</v-btn
                                >
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="4" class="pb-md-0 pt-md-0">
                            <v-checkbox
                              v-model="editMultiItem.trial"
                              class="mx-2"
                              label="Trial"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="4" class="pb-md-0 pt-md-0">
                            <v-checkbox
                              v-model="editMultiItem.notForResale"
                              class="mx-2"
                              label="NFR"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="4" class="pb-md-0 pt-md-0">
                            <v-checkbox
                              v-model="editMultiItem.mirror"
                              class="mx-2"
                              label="Mirror"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="accent" text @click="closeMultiEdit"
                        >Cancel</v-btn
                      >
                      <v-btn color="primary" text @click="multiEdit"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <!-- change user dialog -->

                <v-dialog v-model="dialog.changeUser" max-width="500px">
                  <template v-slot:activator="{ on }">
                    <v-list-item @click="() => {}" v-on="on">
                      <v-list-item-title>Change User</v-list-item-title>
                    </v-list-item>
                  </template>
                  <v-card>
                    <v-card-title class="justify-center">
                      <span class="headline"
                        >Moving {{ selected.length }} licences</span
                      >
                    </v-card-title>

                    <v-card-text class="pb-md-0">
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            class="pb-md-0 pt-md-0"
                          >
                            <v-select
                              v-model="movingUserId"
                              :items="users"
                              item-text="name"
                              item-value="id"
                              label="New User"
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="accent" text @click="closeChangeUser"
                        >Cancel</v-btn
                      >
                      <v-btn color="primary" text @click="changeUser"
                        >Move</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <!-- change type dialog -->

                <v-dialog v-model="dialog.changeType" max-width="500px">
                  <template v-slot:activator="{ on }">
                    <v-list-item @click="() => {}" v-on="on">
                      <v-list-item-title>Change Type</v-list-item-title>
                    </v-list-item>
                  </template>
                  <v-card>
                    <v-card-title class="justify-center">
                      <span class="headline"
                        >Changing type of {{ selected.length }} licences</span
                      >
                    </v-card-title>

                    <v-card-text class="pb-md-0">
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            class="pb-md-0 pt-md-0"
                          >
                            <v-select
                              v-model="newLicenceType"
                              :items="licenceTypes"
                              item-text="text"
                              item-value="value"
                              label="New Licence type"
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="accent" text @click="closeChangeType"
                        >Cancel</v-btn
                      >
                      <v-btn color="primary" text @click="changeType"
                        >Change</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <!-- change reseller dialog -->

                <v-dialog v-model="dialog.changeReseller" max-width="500px">
                  <template v-slot:activator="{ on }">
                    <v-list-item @click="() => {}" v-on="on">
                      <v-list-item-title>Change Reseller</v-list-item-title>
                    </v-list-item>
                  </template>
                  <v-card>
                    <v-card-title class="justify-center">
                      <span class="headline"
                        >Changing reseller of
                        {{ selected.length }} licences</span
                      >
                    </v-card-title>

                    <v-card-text class="pb-md-0">
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            class="pb-md-0 pt-md-0"
                          >
                            <v-select
                              v-model="newResellerId"
                              :items="resellers"
                              item-text="company"
                              item-value="id"
                              label="Reseller"
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="accent" text @click="closeChangeReseller"
                        >Cancel</v-btn
                      >
                      <v-btn color="primary" text @click="changeReseller"
                        >Change</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <!-- delete dialog -->
              </v-list>
            </v-menu>

            <v-spacer />
            <v-dialog v-model="dialog.multiSelect" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn dark class="mr-2 primary" v-on="on">Multi-Select</v-btn>
              </template>
              <v-card>
                <v-card-title class="justify-center">
                  <span class="headline">Select multiple licences</span>
                </v-card-title>

                <v-card-text class="pb-md-0">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12" class="pb-md-0 pt-md-0"
                        >Insert the Id of licences to select below: one Id in a
                        single row</v-col
                      >
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="12" md="12" class="pb-md-0 pt-md-0">
                        <v-textarea
                          clearable
                          rows="6"
                          row-height="10"
                          :error="
                            errorMultiSelectLicences &&
                              errorMultiSelectLicences.length > 0
                          "
                          :error-messages="errorMultiSelectLicences"
                          no-resize
                          v-model="multiSelectLicences"
                          @input="parseMultiSelectLicences"
                          @click:clear="clearErrorMultiSelectLicences()"
                        ></v-textarea>
                        Licences to select:
                        {{ parsedMultiSelectLicences.length }}
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="accent" text @click="closeMultiSelectLicences()"
                    >Cancel</v-btn
                  >
                  <v-btn color="primary" text @click="selectMulti"
                    >Select</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="on">
                  Export
                </v-btn>
              </template>
              <v-list>
                <v-list-item link="">
                  <download-csv separator-excel :data="getExportableItems()">
                    CSV
                  </download-csv>
                </v-list-item>
                <v-list-item @click="openExportWallNetFiles()">
                  <v-list-item-title>WallNet files</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              class="mr-2"
              small
              text
              dark
              icon
              v-on:click="hideShowFilters()"
            >
              <v-icon>mdi-filter</v-icon>
            </v-btn>
            <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details
              clearable
              append-icon="mdi-magnify"
            ></v-text-field>
          </v-toolbar>
          <v-toolbar flat dark v-show="filters.visible">
            <v-row class="mb-6 mt-6" align="center" justify="end">
              <v-col cols="1">
                <v-checkbox
                  v-model="filters.using"
                  class="mt-sm-6"
                  label="Using"
                ></v-checkbox>
              </v-col>
              <v-col cols="3" lg="2" md="2" sm="3" xs="3">
                <v-select
                  dense
                  :items="hardwareIds"
                  menu-props="auto"
                  v-model="filters.hardwareId"
                  hide-details
                  clearable
                  label="Filter by HardwareId"
                ></v-select>
              </v-col>
              <v-col cols="3" lg="2" md="2" sm="3" xs="3">
                <v-select
                  dense
                  :items="getLicenceTypes()"
                  menu-props="auto"
                  v-model="filters.licenceType"
                  hide-details
                  clearable
                  label="Filter by type"
                ></v-select>
              </v-col>
              <v-col cols="3" lg="2" md="2" sm="3" xs="3">
                <v-select
                  dense
                  :items="resellers"
                  item-text="company"
                  item-value="id"
                  menu-props="auto"
                  clearable
                  v-model="filters.reseller"
                  hide-details
                  label="Filter by reseller"
                ></v-select>
              </v-col>
              <v-col cols="3" lg="2" md="2" sm="3" xs="3">
                <v-menu
                  ref="menuFilterStartDate"
                  v-model="menuFilterStartDate"
                  :close-on-content-click="false"
                  :return-value.sync="filters.startDate"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="formattedFilterStartDate"
                      label="Start date"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      hide-details
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-card>
                    <v-row dense no-gutters>
                      <v-col col="4" align="center">Compare</v-col>
                      <v-col col="4" align="center" justify="center">
                        <v-select
                          dense
                          flat
                          hide-details
                          menu-props="auto"
                          :items="filters.dateOps"
                          v-model="filters.startDateOp"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-date-picker
                      v-model="filters.startDate"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="accent"
                        @click="menuFilterStartDate = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.menuFilterStartDate.save(filters.startDate)
                        "
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-card>
                </v-menu>
              </v-col>
              <v-col cols="3" lg="2" md="2" sm="3" xs="3">
                <v-menu
                  ref="menuFilterEndDate"
                  v-model="menuFilterEndDate"
                  :close-on-content-click="false"
                  :return-value.sync="filters.endDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="formattedFilterEndDate"
                      label="End date"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      hide-details
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-card>
                    <v-row dense no-gutters>
                      <v-col col="4" align="center">Compare</v-col>
                      <v-col col="4" align="center" justify="center">
                        <v-select
                          dense
                          flat
                          hide-details
                          menu-props="auto"
                          :items="filters.dateOps"
                          v-model="filters.endDateOp"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-date-picker
                      v-model="filters.endDate"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="accent"
                        @click="menuFilterEndDate = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menuFilterEndDate.save(filters.endDate)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-card>
                </v-menu>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>

        <template v-slot:[`item.notForResale`]="{ item }">
          <div v-if="item.notForResale">
            <v-icon>mdi-check</v-icon>
          </div>
        </template>
        <template v-slot:[`item.trial`]="{ item }">
          <div v-if="item.trial">
            <v-icon>mdi-check</v-icon>
          </div>
        </template>
        <template v-slot:[`item.mirror`]="{ item }">
          <div v-if="item.mirror">
            <v-icon>mdi-check</v-icon>
          </div>
        </template>
        <template v-slot:[`item.backup`]="{ item }">
          <div v-if="item.backup">
            <v-icon>mdi-check</v-icon>
          </div>
        </template>
        <template v-slot:[`item.startDate`]="{ item }">{{
          item.startDate | date
        }}</template>
        <template v-slot:[`item.endDate`]="{ item }">{{
          item.endDate | date
        }}</template>
        <template v-slot:[`item.licenceType`]="{ item }">{{
          item.licenceType | licenceType
        }}</template>
        <template v-slot:[`item.resellerId`]="{ item }">{{
          formatReseller(item.resellerId)
        }}</template>

        <template v-slot:[`item.action`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small class="mr-2" @click="deleteItem(item)"
            >mdi-delete</v-icon
          >
          <v-icon small @click="showHistory(item)">mdi-information</v-icon>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <p class="mt-2">Id: {{ item.id }}</p>
            <p>HardwareId: {{ item.hardwareId }}</p>
            <p>Backup hardwareId: {{ item.backupHardwareId }}</p>
            <p>Last update: {{ item.lastUpdate | date }}</p>
            <p>Description: {{ item.description }}</p>
          </td>
        </template>
      </v-data-table>

      <!-- history -->
      <v-dialog dark v-model="dialog.history" max-width="90%">
        <v-card>
          <v-card-title>
            <span class="headline">History of {{ history.id }}</span>
            <v-spacer />
            <v-btn color="primary">
              <download-csv :data="history.list">Export</download-csv>
            </v-btn>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-container class="fill-height" fluid>
              <v-row>
                <v-col>
                  <v-data-table
                    ref="historyDT"
                    :headers="historyHeaders"
                    :items="history.list"
                    :loading="history.loading"
                    :loading-text="loadingText"
                    disable-filtering
                    disable-pagination
                    disable-sort
                    hide-default-footer
                    height="40vh"
                    dark
                  >
                    <template v-slot:[`item.userId`]="{ item }">{{
                      formatUser(item.userId)
                    }}</template>
                    <template v-slot:[`item.startDate`]="{ item }">{{
                      item.startDate | date
                    }}</template>
                    <template v-slot:[`item.endDate`]="{ item }">{{
                      item.endDate | date
                    }}</template>
                    <template v-slot:[`item.licenceType`]="{ item }">{{
                      item.licenceType | licenceType
                    }}</template>
                    <template v-slot:[`item.resellerId`]="{ item }">{{
                      formatReseller(item.resellerId)
                    }}</template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog.history = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="exportWallNetDialog.on" max-width="650px">
        <v-card>
          <v-card-title> Export WallNet files </v-card-title>
          <v-card-text>
            <v-form v-model="exportWallNetDialog.formValid" ref="wallnetform">
              <v-row>
                <v-col cols="12">
                  <v-select
                    dense
                    :items="hardwareIds"
                    menu-props="auto"
                    :rules="[rules.required]"
                    v-model="exportWallNetItem.hardwareId"
                    label="Select licences by HardwareId"
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="accent" text @click="exportWallNetDialog.on = false"
              >Cancel</v-btn
            >
            <v-btn
              color="primary"
              text
              class="mr-2"
              @click="exportWallNetFiles()"
              >Export</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

import cloneDeep from "lodash/cloneDeep";
import sortBy from "lodash/sortBy";
import uniq from "lodash/uniq";
import moment from "moment";
import costants from "../utils/costants";
import util from "../utils/helpers";
import LicenceService from "../services/LicenceService";
import rulesMixin from "../mixins/rulesMixin";
import tableHeightMixin from "@/mixins/tableHeightMixin";

export default {
  mixins: [rulesMixin, tableHeightMixin],
  data() {
    return {
      dialog: {
        add: false,
        renew: false,
        multiEdit: false,
        changeUser: false,
        changeType: false,
        changeReseller: false,
        info: false,
        history: false,
        multiDelete: false,
        multiSelect: false,
        recursiveDelete: false,
      },
      history: {
        loading: true,
        list: [],
      },
      multiSelectLicences: null,
      parsedMultiSelectLicences: [],
      errorMultiSelectLicences: null,
      actionMenu: false,
      headers: [
        {
          text: "Type",
          value: "licenceType",
          width: "200px",
          filter: (value) => {
            if (this.filters.licenceType === undefined) return true;
            return value === this.filters.licenceType;
          },
          table: ["licences", "history"],
        },
        {
          text: "User",
          value: "userId",
          table: ["history"],
        },
        {
          text: "Reseller",
          value: "resellerId",
          filter: (value) => {
            if (this.filters.reseller === undefined) return true;
            return value === this.filters.reseller;
          },
          table: ["licences", "history"],
        },
        { text: "Order", value: "orderRef", table: ["licences", "history"] },
        {
          text: "Player",
          value: "playerId",
          table: ["licences"],
          filter: (value) => {
            if (this.filters.using === false) return true;
            return value !== null;
          },
        },
        {
          text: "NFR",
          value: "notForResale",
          table: ["licences"],
          width: "80px",
        },
        { text: "Trial", value: "trial", table: ["licences"], width: "80px" },
        { text: "Mirror", value: "mirror", table: ["licences"], width: "90px" },
        { text: "Backup", value: "backup", table: ["licences"] },
        {
          text: "Start Date",
          width: "120px",
          value: "startDate",
          filter: (value) => {
            if (!this.filters.startDate) return true;
            return this.compareDates(
              value,
              this.filters.startDate,
              this.filters.startDateOp
            );
          },
          table: ["licences", "history"],
        },
        {
          text: "End Date",
          width: "120px",
          value: "endDate",
          filter: (value) => {
            if (!this.filters.endDate) return true;
            return this.compareDates(
              value,
              this.filters.endDate,
              this.filters.endDateOp
            );
          },
          table: ["licences", "history"],
        },
        {
          text: "HId",
          value: "hardwareId",
          filter: (value) => {
            if (!this.filters.hardwareId) return true;
            return value == this.filters.hardwareId;
          },
          table: ["licences"],
          align: " d-none",
        },
        {
          text: "Actions",
          width: "100px",
          value: "action",
          sortable: false,
          table: ["licences"],
        },
      ],
      search: null,
      loadingText: "Loading...",
      historyLoading: false,
      editedIndex: -1,
      selected: [],
      editedItem: {
        userId:
          this.$store.state.route.params.userId ||
          this.$store.state.auth.userId,
        licenceType: 1,
        quantity: 1,
        resellerId: null,
        orderRef: null,
        hardwareId: null,
        backupHardwareId: null,
        description: null,
        startDate: new Date().toISOString().substr(0, 10),
        endDate: null,
        trial: false,
        notForResale: false,
        mirror: false,
        backup: false,
      },
      defaultItem: {
        userId:
          this.$store.state.route.params.userId ||
          this.$store.state.auth.userId,
        licenceType: 1,
        quantity: 1,
        resellerId: null,
        orderRef: null,
        description: null,
        hardwareId: null,
        startDate: new Date().toISOString().substr(0, 10),
        endDate: null,
        trial: false,
        notForResale: false,
        mirror: false,
        backup: false,
      },
      editMultiItem: {
        userId:
          this.$store.state.route.params.userId ||
          this.$store.state.auth.userId,
        licenceType: 1,
        quantity: 1,
        resellerId: null,
        orderRef: null,
        description: null,
        hardwareId: null,
        startDate: new Date().toISOString().substr(0, 10),
        endDate: null,
        trial: false,
        notForResale: false,
        mirror: false,
        backup: false,
      },
      menuStartDate: false,
      menuEndDate: false,
      menuMultiEditStartDate: false,
      menuMultiEditEndDate: false,
      menuRenewStartDate: false,
      menuRenewEndDate: false,
      renewMultiItem: {
        userId:
          this.$store.state.route.params.userId ||
          this.$store.state.auth.userId,
        orderRef: null,
        endDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
          .toISOString()
          .substr(0, 10),
        startDate: null,
      },
      menuFilterStartDate: false,
      menuFilterEndDate: false,
      movingUserId:
        this.$store.state.route.params.userId || this.$store.state.auth.userId,
      newLicenceType: 1,
      newResellerId: null,
      filters: {
        licenceType: undefined,
        reseller: undefined,
        startDate: null,
        endDate: null,
        visible: false,
        dateOps: [">", "=", "<"],
        startDateOp: "=",
        endDateOp: "=",
        using: false,
        hardwareId: null,
      },
      exportWallNetItem: {
        hardwareId: null,
      },
      exportWallNetDialog: {
        on: false,
        formValid: false,
      },
    };
  },
  computed: {
    ...mapState({
      licences: (state) => state.licences.list,
      resellers: (state) => {
        var list = state.resellers.list.map((x) => {
          return {
            id: x.id,
            company: x.company,
          };
        });

        list = sortBy(list, "company");

        return list;
      },
      hardwareIds: (state) => {
        var list = state.licences.list
          .filter((x) => {
            if (x.hardwareId !== undefined && x.hardwareId != null) {
              return true;
            }
          })
          .map((x) => {
            return x.hardwareId;
          });

        return list.sort();
      },
      users: (state) =>
        state.users.list
          .map((x) => {
            return {
              id: x.id,
              name: x.name,
            };
          })
          .sort((a, b) => {
            var nameA = a.name.toUpperCase();
            var nameB = b.name.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          }),
      loading: (state) => state.licences.loading,
      errorLoading: (state) => state.app.errorLoading,
      errorOperation: (state) => state.app.errorOperation,
    }),
    licencesHeaders() {
      return this.headers.filter(
        (header) => header.table.indexOf("licences") !== -1
      );
    },
    historyHeaders() {
      return this.headers.filter(
        (header) => header.table.indexOf("history") !== -1
      );
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Licence" : "Edit Licence";
    },
    formattedStartDate() {
      return util.formatDate(this.editedItem.startDate);
    },
    formattedMultiEditStartDate() {
      return util.formatDate(this.editMultiItem.startDate);
    },
    formattedMultiEditEndDate() {
      return util.formatDate(this.editMultiItem.endDate);
    },
    formattedRenewStartDate() {
      return util.formatDate(this.renewMultiItem.startDate);
    },
    formattedRenewEndDate() {
      return util.formatDate(this.renewMultiItem.endDate);
    },
    licenceTypes() {
      return sortBy(costants.LicencesTypes, "text");
    },
    formattedFilterEndDate: {
      get: function() {
        return this.filters.endDate
          ? this.filters.endDateOp + " " + util.formatDate(this.filters.endDate)
          : "";
      },
      // setter
      set: function(newValue) {
        this.filters.endDate = newValue;
      },
    },
    formattedEndDate: {
      get: function() {
        if (this.editedItem.endDate === null) return null;
        return util.formatDate(this.editedItem.endDate);
      },
      // setter
      set: function(newValue) {
        this.editedItem.endDate = newValue;
      },
    },
    formattedFilterStartDate: {
      get: function() {
        return this.filters.startDate
          ? this.filters.startDateOp +
              " " +
              util.formatDate(this.filters.startDate)
          : "";
      },
      // setter
      set: function(newValue) {
        this.filters.startDate = newValue;
      },
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    closeMultiSelectLicences() {
      this.clearErrorMultiSelectLicences();
      this.parsedMultiSelectLicences = [];
      this.multiSelectLicences = null;
      this.errorMultiSelectLicences = null;
      this.dialog.multiSelect = false;
    },
    clearErrorMultiSelectLicences() {
      this.errorMultiSelectLicences = null;
    },
    selectMulti() {
      var parsedLicences = this.parsedMultiSelectLicences;
      var newSelection = this.licences.filter(function(licence) {
        return parsedLicences.includes(licence.id);
      });

      if (newSelection.length == parsedLicences.length) {
        this.selected = newSelection;
        this.closeMultiSelectLicences();
      } else {
        var licences = this.licences.map((x) => x.id);
        var errorlicences = parsedLicences.filter((e) => !licences.includes(e));

        this.errorMultiSelectLicences = "Not found licences : " + errorlicences;
      }
    },
    parseMultiSelectLicences() {
      if (this.multiSelectLicences) {
        this.parsedMultiSelectLicences = uniq(
          this.multiSelectLicences.trim().split("\n")
        ).filter(function(value) {
          return value.length > 0;
        });
      } else {
        this.parsedMultiSelectLicences = [];
      }
    },
    openExportWallNetFiles() {
      this.exportWallNetDialog.on = true;

      if (this.$refs.wallnetform) {
        this.$refs.wallnetform.reset();
        this.$refs.wallnetform.resetValidation();
      }
    },
    exportWallNetFiles() {
      if (this.$refs.wallnetform.validate()) {
        this.exportWallNetDialog.on = false;

        // var payload
        this.$store
          .dispatch(
            "licences/exportControlRoomFiles",
            this.exportWallNetItem.hardwareId
          )
          .then((response) => {
            let blob = new Blob([response.data], { type: "application/zip" });
            let url = window.URL.createObjectURL(blob);

            let link = document.createElement("a");
            link.href = url;
            link.download = "licences.zip";
            link.click();
          });
      }
      // var payload
      //  this.$store.dispatch("licences/deleteMulti", this.selected);
    },
    getSortedLicences() {
      if (this.licences !== null) {
        return sortBy(this.licences, "startDate");
      }
    },
    getExportableItems() {
      if (this.selected.length > 0) {
        return this.selected;
      }
      if (this.$refs && this.$refs.licencesDT && this.$refs.licencesDT._data) {
        return this.licences;
      }
      return [];
    },
    hideShowFilters() {
      this.filters.visible = !this.filters.visible;
    },
    getLicenceTypes() {
      var items = cloneDeep(costants.LicencesTypes);
      return sortBy(items, "text");
    },
    customFilter: (value, search, item) => {
      if (value === undefined || value === null || value === "") {
        return false;
      }

      search = search.toString().toLowerCase();

      var findId =
        item.id
          .toString()
          .toLowerCase()
          .indexOf(search) >= 0;

      var findPlayerId = false;
      if (item.playerId !== null) {
        findPlayerId =
          item.playerId
            .toString()
            .toLowerCase()
            .indexOf(search) >= 0;
      }

      var findDescription = false;
      if (item.description !== null) {
        findDescription =
          item.description
            .toString()
            .toLowerCase()
            .indexOf(search) >= 0;
      }

      var findOderRef = false;
      if (item.orderRef !== null) {
        findOderRef =
          item.orderRef
            .toString()
            .toLowerCase()
            .indexOf(search) >= 0;
      }

      return findId || findPlayerId || findDescription || findOderRef;
    },
    formatReseller(resellerId) {
      var reseller = this.resellers.find(function(item) {
        return item.id === resellerId;
      });
      if (reseller) {
        return reseller.company;
      }
      return "";
    },
    formatUser(userId) {
      var user = this.users.find(function(item) {
        return item.id === userId;
      });
      if (user) {
        return user.name;
      }
      return "";
    },
    getSelectedLength() {
      return this.selected.length;
    },
    editItem(item) {
      this.editedIndex = this.licences.indexOf(item);
      this.editedItem = cloneDeep(item);
      this.dialog.add = true;
    },
    deleteItem(item) {
      this.selected = [item];
      this.dialog.multiDelete = true;
    },
    deleteMulti() {
      this.$store.dispatch("licences/deleteMulti", {
        items: this.selected,
        recursive: this.dialog.recursiveDelete,
      });
      this.closeDeleteMulti();
    },
    openAdd() {
      this.editedItem.startDate = moment().toISOString();
      this.editedItem.endDate = moment()
        .add(1, "years")
        .toISOString();
      this.dialog.add = true;
    },
    save() {
      if (this.editedIndex > -1) {
        this.$store.dispatch("licences/edit", this.editedItem);
      } else {
        this.$store.dispatch("licences/add", this.editedItem);
      }
      this.selected = [];
      this.closeAdd();
    },
    closeAdd() {
      this.dialog.add = false;
      //reset edited item
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    renew() {
      this.$store.dispatch("licences/renew", {
        licences: this.selected.map((x) => {
          return {
            licenceId: x.id,
            startDate: this.renewMultiItem.startDate,
            endDate: this.renewMultiItem.endDate,
          };
        }),
        resellerId: this.selected[0].resellerId,
        orderRef: this.renewMultiItem.orderRef,
        userId: this.renewMultiItem.userId,
      });
      this.closeRenew();
      this.selected = [];
    },
    closeRenew() {
      this.dialog.renew = false;
    },
    multiEdit() {
      this.$store.dispatch("licences/editMulti", {
        licences: this.selected.map((x) => x.id),
        info: this.editMultiItem,
      });
      this.closeMultiEdit();
    },
    closeMultiEdit() {
      this.dialog.multiEdit = false;
      this.actionMenu = false;
      setTimeout(() => {
        this.editMultiItem = Object.assign({}, this.defaultItem);
      }, 300);
    },
    changeUser() {
      this.$store.dispatch("licences/move", {
        licences: this.selected.map((x) => x.id),
        userId: this.movingUserId,
      });
      this.closeChangeUser();
      this.movingUserId =
        this.$store.state.route.params.userId || this.$store.state.auth.userId;
      this.selected = [];
    },
    closeChangeUser() {
      this.dialog.changeUser = false;
      this.actionMenu = false;
    },
    closeDeleteMulti() {
      this.dialog.multiDelete = false;
      this.dialog.recursiveDelete = false;
      this.actionMenu = false;
      this.selected = [];
    },
    compareDates(date1, date2, op) {
      var d1 = moment(date1).startOf("day");
      var d2 = moment(date2).startOf("day");

      if (op == ">") {
        return d1.isAfter(d2);
      } else if (op == "<") {
        return d1.isBefore(d2);
      }

      return d1.isSame(d2);
    },
    changeType() {
      this.$store.dispatch("licences/changeType", {
        licences: this.selected.map((x) => x.id),
        licenceType: this.newLicenceType,
      });
      this.closeChangeType();
      this.newLicenceType = 1;
      this.selected = [];
    },
    closeChangeType() {
      this.dialog.changeType = false;
      this.actionMenu = false;
    },
    changeReseller() {
      this.$store.dispatch("licences/changeReseller", {
        licences: this.selected.map((x) => x.id),
        resellerId: this.newResellerId,
      });
      this.closeChangeReseller();
      this.newResellerId = null;
      this.selected = [];
    },
    closeChangeReseller() {
      this.dialog.changeReseller = false;
      this.actionMenu = false;
    },
    showHistory(item) {
      var vm = this;
      vm.dialog.history = true;
      vm.history.id = item.id;
      LicenceService.details(item.id)
        .then((response) => {
          vm.history.list = util.toCamelCase(response.data);
        })
        .catch(() => {
          vm.history.list = [];
        })
        .finally(() => {
          vm.history.loading = false;
        });
    },
  },
  mounted() {
    let title = "Licences of ";

    let userId = this.$store.state.route.params.userId;
    if (userId) {
      var user = this.$store.state.users.list.find((user) => {
        return user.id === userId;
      });
      if (user) {
        title += user.name;
      } else {
        title += "not available";
      }
    } else {
      title += "Wallin";
    }

    this.$store.dispatch("licences/load");
    this.$store.dispatch("app/updateTitle", title);
  },
};
</script>
