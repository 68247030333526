import ResellerService from "../../services/ResellerService";
import utils from "../../utils/helpers";

// initial state
const state = {
  list: [],
  loading: false,
};

// getters
const getters = {
  list: (state) => {
    return state.list;
  },
  isLoading: (state) => {
    return state.loading;
  },
};

// actions
const actions = {
  load: ({ commit }) => {
    commit("setLoading", true);
    ResellerService.getList()
      .then((response) => {
        var items = response.data.map((l) => {
          return utils.toCamelCase(l);
        });
        commit("setList", items);
        commit("app/setErrorLoading", false, {
          root: true,
        });
      })
      .catch(() => {
        commit("setList", []);
        commit("app/setErrorLoading", true, {
          root: true,
        });
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  add: ({ commit }, item) => {
    commit("setLoading", true);
    ResellerService.add(item)
      .then((response) => {
        var result = utils.toCamelCase(response.data);
        commit("add", result);
        commit("app/setErrorOperation", false, {
          root: true,
        });
      })
      .catch(() => {
        commit("app/setErrorOperation", true, {
          root: true,
        });
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  edit: ({ commit }, item) => {
    commit("setLoading", true);
    ResellerService.edit(item)
      .then((response) => {
        var result = utils.toCamelCase(response.data);
        commit("edit", result);
        commit("app/setErrorOperation", false, {
          root: true,
        });
      })
      .catch(() => {
        commit("app/setErrorOperation", true, {
          root: true,
        });
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  deleteMulti: ({ commit }, data) => {
    commit("setLoading", true);

    // create promises for each delete request
    var promises = data.items.map((item) => {
      return new Promise(function(resolve, reject) {
        ResellerService.delete(item.id)
          .then((response) => {
            commit("delete", item);
            if (response.data) {
              var result = utils.toCamelCase(response.data);
              commit("add", [result]);
            }

            resolve(item);
          })
          .catch(() => {
            reject();
          });
      });
    });

    // process the promises results
    Promise.all(promises)
      .then(function() {})
      .catch(function() {
        commit("app/setErrorOperation", true, {
          root: true,
        });
      })
      .finally(() => {
        commit("app/setErrorOperation", false, {
          root: true,
        });

        commit("setLoading", false);
      });
  },
};

// mutations
const mutations = {
  setList: (state, resellers) => {
    state.list = resellers;
  },
  add: (state, item) => {
    state.list.push(item);
  },
  edit: (state, item) => {
    var index = state.list.findIndex((x) => x.id === item.id);
    if (index < 0) {
      return;
    }
    state.list.splice(index, 1, item);
  },
  delete: (state, item) => {
    var index = state.list.findIndex((x) => x.id === item.id);
    if (index < 0) {
      return;
    }
    state.list.splice(index, 1);
  },
  setLoading: (state, loading) => {
    state.loading = loading;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
