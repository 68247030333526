// initial state
const state = {
  theme: "dark",
  title: "Wallin Licence Manager",
  drawer: true,
  errorLoading: false,
  errorOperation: false,
  loaded: false,
};

// getters
const getters = {
  drawer: (state) => {
    return state.drawer;
  },
  theme: (state) => {
    return state.theme;
  },
  title: (state) => {
    return state.title;
  },
  errorLoading: (state) => {
    return state.errorLoading;
  },
  errorOperation: (state) => {
    return state.errorOperation;
  },
};

// actions
const actions = {
  updateTheme: ({ commit }, theme) => {
    commit("setTheme", theme);
  },
  updateTitle: ({ commit }, title) => {
    commit("setTitle", title);
  },
  toggleDrawer: ({ commit }) => {
    commit("toggleDrawer");
  },
  updateErrorLoading: ({ commit }, error) => {
    commit("setErrorLoading", error);
  },
};

// mutations
const mutations = {
  setTheme: (state, theme) => {
    state.theme = theme;
  },
  setTitle: (state, title) => {
    state.title = title;
  },
  toggleDrawer: (state) => {
    state.drawer = !state.drawer;
  },
  setErrorLoading: (state, error) => {
    state.errorLoading = error;
  },
  setErrorOperation: (state, error) => {
    state.errorOperation = error;
  },
  SET_LOADED(state) {
    state.loaded = true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
