import moment from 'moment'
import costants from '../utils/costants'
import {
	camelCase,
	isPlainObject
} from 'lodash'

export default {
	toCamelCase: toCamelCase,
	formatDate: (value, format) => {
		if (!value) {
			return null;
		}

		if (!format) {
			format = "L";
		}
		return moment(value).format(format);
	},
	formatLicence: (value) => {

		if (value === undefined || value === null) {
			return null;
		}
		return costants.LicencesTypes[value]['text'];
	}
}

function toCamelCase(obj) {
	if (Array.isArray(obj)) {
		return obj.map(v => toCamelCase(v));
	} else if (isPlainObject(obj)) {
		return Object.keys(obj).reduce(
			(result, key) => ({
				...result,
				[camelCase(key)]: toCamelCase(obj[key]),
			}), {},
		);
	}
	return obj;
}