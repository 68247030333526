<template>
  <v-container>
    <v-row>
      <snackbar-error></snackbar-error>
      <v-col v-for="(item, i) in summary" :key="i" cols="12" sm="12" lg="4" md="4" xs="12">
        <v-card>
          <div class="d-flex flex-no-wrap justify-space-between primary">
            <v-card-title>
              <v-btn
                class="title font-weight-light"
                text
                @click="onClick(item.licenceType)"
              >{{ item.licenceType | licenceType }}</v-btn>
            </v-card-title>
            <v-card-title>
              <v-btn
                class="title font-weight-light"
                text
                @click="onClick(item.licenceType)"
              >{{ item.total }}</v-btn>
            </v-card-title>
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-row>
              <v-col
                align="center"
                cols="12"
                sm="12"
                md="4"
                lg="4"
                xs="12"
                class="pl-sm-0 pb-md-0 pt-md-0"
              >
                <v-btn
                  text
                  @click="onClick(item.licenceType, 'notForResale')"
                >NFR: {{item.notForResale}}</v-btn>
              </v-col>
              <v-col
                align="center"
                cols="12"
                sm="12"
                md="4"
                lg="4"
                xs="12"
                class="pl-sm-0 pb-md-0 pt-md-0"
              >
                <v-btn @click="onClick(item.licenceType, 'trial')" text>TRIAL: {{item.trial}}</v-btn>
              </v-col>
              <v-col
                align="center"
                cols="12"
                sm="12"
                md="4"
                lg="4"
                xs="12"
                class="pl-sm-0 pb-md-0 pt-md-0"
              >
                <v-btn @click="onClick(item.licenceType, 'mirror')" text>MIRROR: {{item.mirror}}</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import costants from "../utils/costants";

export default {
  name: "Dashboard",
  data() {
    return {
      licenceTypes: costants.LicencesTypes
    };
  },
  computed: {
    ...mapState({
      summary: state => state.statistics.summary,
      loading: state => state.statistics.loading
    })
  },
  methods: {
    onClick: function(type, mode) {
      var params = "/" + type;
      if (mode !== undefined) {
        params += "/" + mode;
      }

      this.$router.push("/dashboard/statistics/summarytypes" + params);
    }
  },
  mounted() {
    this.$store.dispatch("statistics/summary");
    this.$store.dispatch("app/updateTitle", "Wallin Licence Manager");
  }
};
</script>