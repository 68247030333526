<template>
  <v-row v-resize="onResize">
    <v-col>
      <snackbar-error></snackbar-error>
      <confirm-dialog ref="confirm"></confirm-dialog>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="orders"
        :items-per-page="10"
        :search="search"
        :custom-filter="customFilter"
        :expanded.sync="expanded"
        show-select
        fixed-header
        :height="getContentHeight"
        show-expand
        dark
      >
        <template v-slot:top>
          <v-toolbar flat dark>
            <v-btn
              color="accent"
              dark
              class="ml-2"
              v-show="selected.length > 0"
              @click="deleteOrders()"
              >Delete</v-btn
            >
            <v-spacer />
            <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details
              clearable
              append-icon="mdi-magnify"
            ></v-text-field>
          </v-toolbar>
          <v-toolbar flat dark>
            <v-menu
              ref="filterDatesRange"
              v-model="menus.filterDatesRange"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formattedDatesRange"
                  label="Select range"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterDatesRange"
                range
                locale="it"
                :first-day-of-week="1"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="menus.filterDatesRange = false"
                >
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="loadOrders">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-spacer />
            <v-select
              v-model="filterStatus"
              :items="filterStatuses"
              label="Status"
            ></v-select>
          </v-toolbar>
        </template>

        <template v-slot:[`item.createdAt`]="{ item }">
          {{ item.createdAt | date }}
        </template>

        <template v-slot:[`item.orderStatus`]="{ item }">
          {{ item.orderStatus | orderStatus }}
        </template>

        <template v-slot:[`item.userId`]="{ item }">
          {{ users[item.userId].name }}
        </template>
        <template v-slot:[`item.resellerId`]="{ item }">
          <span v-if="item.resellerId">{{
            resellers[item.resellerId].company
          }}</span>
        </template>

        <template v-slot:[`item.attachmentUrl`]="{ item }">
          <div v-if="item.attachmentUrl">
            <a :href="item.attachmentUrl" target="_blank"
              ><v-icon>mdi-attachment</v-icon></a
            >
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon v-if="item.orderStatus === 0" @click="approve(item)">
            <v-icon color="green">mdi-check-bold</v-icon>
          </v-btn>

          <v-btn icon v-if="item.orderStatus === 0" @click="reject(item)">
            <v-icon color="red">mdi-cancel</v-icon>
          </v-btn>

          <v-btn icon @click="openDetails(item)">
            <v-icon>mdi-information</v-icon>
          </v-btn>

          <!-- <v-icon
            v-if="item.orderStatus === 0"
            small
            class="mr-2"
            @click.stop="approve(item)"
            >mdi-check-bold</v-icon
          > -->
          <!-- <v-icon
            v-if="item.orderStatus === 0"
            small
            class="mr-2"
            @click.stop="reject(item)"
            >mdi-cancel</v-icon
          > -->

          <!-- <v-icon small @click.stop="openDetails(item)">mdi-information</v-icon> -->
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <p class="mt-2">Order Id: {{ item.id }}</p>
          </td>
        </template>
      </v-data-table>

      <!-- details -->
      <v-dialog dark v-model="dialogDetails.open" width="70%">
        <v-card>
          <v-card-title>
            <span class="headline">Details of {{ dialogDetails.id }}</span>
            <!-- <v-spacer />
            <v-btn color="primary">
              <download-csv :data="details.list">Export</download-csv>
            </v-btn> -->
          </v-card-title>
          <v-card-text class="pa-0">
            <v-container class="fill-height" fluid>
              <v-row v-show="activationDetails.length > 0">
                <v-col>
                  <h3 class="primary--text">New activations</h3>
                  <v-data-table
                    ref="activationDetailsDT"
                    :headers="activationHeaders"
                    :items="activationDetails"
                    :loading="dialogDetails.loading"
                    loading-text="Loading order activation details"
                    disable-filtering
                    disable-pagination
                    disable-sort
                    hide-default-footer
                    dense
                    height="30vh"
                    dark
                  >
                    <template v-slot:[`item.startDate`]="{ item }">{{
                      item.startDate | date
                    }}</template>
                    <template v-slot:[`item.endDate`]="{ item }">{{
                      item.endDate | date
                    }}</template>
                    <template v-slot:[`item.licenceType`]="{ item }">{{
                      item.licenceType | licenceType
                    }}</template>

                    <template v-slot:[`item.notForResale`]="{ item }">
                      <div v-if="item.notForResale">
                        <v-icon>mdi-check</v-icon>
                      </div>
                    </template>
                    <template v-slot:[`item.trial`]="{ item }">
                      <div v-if="item.trial">
                        <v-icon>mdi-check</v-icon>
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row v-show="renewalDetails.length > 0">
                <v-col>
                  <h3 class="primary--text">Renewals</h3>
                  <v-data-table
                    ref="renewalDetailsDT"
                    :headers="renewalHeaders"
                    :items="renewalDetails"
                    :loading="dialogDetails.loading"
                    loading-text="Loading order renewal details"
                    disable-filtering
                    disable-pagination
                    disable-sort
                    hide-default-footer
                    dense
                    height="30vh"
                    dark
                  >
                    <template v-slot:[`item.renewalEndDate`]="{ item }">{{
                      item.renewalEndDate | date
                    }}</template>
                    <template v-slot:[`item.licenceType`]="{ item }">{{
                      item.licenceType | licenceType
                    }}</template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialogDetails.open = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

import tableHeightMixin from "@/mixins/tableHeightMixin";
import costants from "../utils/costants";
import OrderService from "@/services/OrderService";
import utils from "@/utils/helpers";

import moment from "moment";

export default {
  mixins: [tableHeightMixin],
  components: {
    ConfirmDialog: () => import("@/components/core/ConfirmDialog"),
  },
  data() {
    return {
      headers: [
        // { text: "Id", value: "id" },
        { text: "Created", value: "createdAt" },
        {
          text: "Status",
          value: "orderStatus",
          filter: (value) => {
            if (this.filterStatus === -1) {
              return true;
            }
            return value === this.filterStatus;
          },
        },
        { text: "OrderRef ", value: "orderRef" },
        { text: "Attachment", value: "attachmentUrl" },
        { text: "User", value: "userId" },
        { text: "Reseller", value: "resellerId" },
        { text: "LicencesCount", value: "licencesCount" },
        { text: "Actions", value: "actions" },
      ],
      activationHeaders: [
        { text: "Id", value: "id" },
        { text: "Type", value: "licenceType" },
        { text: "Quantity", value: "quantity" },
        { text: "Start date", value: "startDate" },
        { text: "End date", value: "endDate" },
        { text: "NFR", value: "notForResale" },
        { text: "Trial", value: "trial" },
      ],
      renewalHeaders: [
        { text: "Licence id", value: "licenceId" },
        { text: "Licence type", value: "licenceType" },
        { text: "Renewal end date", value: "renewalEndDate" },
      ],
      search: null,
      editedIndex: -1,
      selected: [],
      expanded: [],
      menus: {
        filterDatesRange: false,
      },
      filterType: -1,
      filterStatus: 0,
      filterDatesRange: [
        moment()
          .add(-30, "days")
          .format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
      activationDetails: [],
      renewalDetails: [],
      dialogDetails: {
        loading: false,
        open: false,
        error: false,
        id: null,
        type: null,
      },
    };
  },
  mounted() {
    this.$store.dispatch("app/updateTitle", "Orders");
    this.header = 112;
  },
  computed: {
    ...mapState({
      //trasform array in object to easy index elements by id
      users: (state) =>
        state.users.list.reduce(
          (obj, item) => Object.assign(obj, { [item.id]: item }),
          {}
        ),
      resellers: (state) =>
        state.resellers.list.reduce(
          (obj, item) => Object.assign(obj, { [item.id]: item }),
          {}
        ),
      orders: (state) => state.orders.list,
      errorLoading: (state) => state.resellers.errorLoading,
      errorOperation: (state) => state.resellers.errorOperation,
    }),
    filterTypes: function() {
      var items = [{ value: -1, text: "All" }];

      items.push(
        ...costants.OrdersTypes.map((t) => {
          return {
            value: t.value,
            text: t.text,
          };
        })
      );
      return items;
    },
    filterStatuses: function() {
      var items = [{ value: -1, text: "All" }];

      items.push(
        ...costants.OrdersStatus.map((t) => {
          return {
            value: t.value,
            text: t.text,
          };
        })
      );
      return items;
    },
    formattedDatesRange: function() {
      if (this.filterDatesRange.length > 1) {
        var fDate1 = moment(this.filterDatesRange[0]).format("DD/MM/YYYY");
        var fDate2 = moment(this.filterDatesRange[1]).format("DD/MM/YYYY");
        return `${fDate1} - ${fDate2}`;
      }
      return "";
    },
  },
  methods: {
    approve: function(item) {
      this.$refs.confirm
        .open("Approve order", "Are you sure?")
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch("orders/approve", item);
          }
        });
    },
    reject: function(item) {
      this.$refs.confirm
        .open("Reject order", "Are you sure?")
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch("orders/reject", item);
          }
        });
    },
    customFilter: (value, search) => {
      if (value === undefined || value === null || value === "") {
        return false;
      }

      if (!search) {
        return true;
      }

      search = search.toString().toLowerCase();

      if (
        value
          .toString()
          .toLowerCase()
          .indexOf(search) >= 0
      ) {
        return true;
      }
    },
    loadOrders: function() {
      this.$store.dispatch("orders/clear");
      this.$store.dispatch("orders/load", {
        startDate: this.filterDatesRange[0],
        endDate: this.filterDatesRange[1],
      });
      this.menus.filterDatesRange = false;
    },
    deleteOrders: function() {
      var text = "order";
      if (this.selected.length > 1) {
        text = "orders";
      }
      this.$refs.confirm
        .open(
          `Delete ${text}`,
          `Are you sure to delete ${this.selected.length} ${text}?`
        )
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch("orders/delete", this.selected);
          }
        });
    },
    openDetails: function(item) {
      this.dialogDetails.error = false;
      this.details = [];
      this.dialogDetails.id = item.id;
      this.dialogDetails.open = true;
      this.dialogDetails.loading = true;

      var promiseActivation = OrderService.getActivationDetails(item.id);
      //  promise = OrderService.getRenewalDetails(item.id);

      promiseActivation
        .then((response) => {
          this.activationDetails = utils.toCamelCase(response.data);
        })
        .catch(() => {
          this.dialogDetails.error = true;
        })
        .finally(() => {
          this.dialogDetails.open = true;
          this.dialogDetails.loading = false;
        });

      var promiseRenewal = OrderService.getRenewalDetails(item.id);

      promiseRenewal
        .then((response) => {
          this.renewalDetails = utils.toCamelCase(response.data);
        })
        .catch(() => {
          this.dialogDetails.error = true;
        })
        .finally(() => {
          this.dialogDetails.open = true;
          this.dialogDetails.loading = false;
        });
    },
  },
};
</script>
