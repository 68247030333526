import OrderService from "@/services/OrderService";
import utils from "@/utils/helpers";

// initial state
const state = {
  list: [],
};

// getters
const getters = {
  list: (state) => {
    return state.list;
  },
};

// actions
const actions = {
  load: ({ commit }, params) => {
    return OrderService.getList(params.startDate, params.endDate)
      .then((response) => {
        var items = response.data.map((l) => {
          return utils.toCamelCase(l);
        });
        commit("SET_LIST", items);
        commit("app/setErrorLoading", false, {
          root: true,
        });
        return items;
      })
      .catch(() => {
        commit("SET_LIST", []);
        commit("app/setErrorLoading", true, {
          root: true,
        });
      })
      .finally(() => {
        //
      });
  },
  clear: ({ commit }) => {
    commit("CLEAR");
  },
  approve: ({ commit }, item) => {
    return OrderService.approve(item)
      .then((response) => {
        var result = utils.toCamelCase(response.data);
        commit("SET_ITEM", result);
        commit("app/setErrorOperation", false, {
          root: true,
        });
        return result;
      })
      .catch(() => {
        commit("app/setErrorOperation", true, {
          root: true,
        });
      })
      .finally(() => {
        //
      });
  },
  reject: ({ commit }, item) => {
    return OrderService.reject(item)
      .then((response) => {
        var result = utils.toCamelCase(response.data);
        commit("SET_ITEM", result);
        commit("app/setErrorOperation", false, {
          root: true,
        });
        return result;
      })
      .catch(() => {
        commit("app/setErrorOperation", true, {
          root: true,
        });
      })
      .finally(() => {
        //
      });
  },
  delete: ({ commit }, data) => {
    // create promises for each delete request
    var promises = data.map((item) => {
      return new Promise(function(resolve, reject) {
        var payload = { id: item.id };
        OrderService.delete(payload)
          .then(() => {
            commit("DELETE", item);
            resolve(item);
          })
          .catch(() => {
            reject();
          });
      });
    });

    // process the promises results
    Promise.all(promises)
      .then(function() {})
      .catch(function() {
        commit("app/setErrorOperation", true, {
          root: true,
        });
      })
      .finally(() => {
        commit("app/setErrorOperation", false, {
          root: true,
        });
      });
  },
  activationDetails: function() {
    //
  },
  renewalDetails: function() {
    //
  },
};

// mutations
const mutations = {
  SET_LIST: (state, orders) => {
    state.list = orders;
  },
  SET_ITEM: (state, item) => {
    var index = state.list.findIndex((x) => x.id === item.id);
    if (index < 0) {
      return;
    }
    state.list.splice(index, 1, item);
  },
  DELETE: (state, item) => {
    var index = state.list.findIndex((x) => x.id === item.id);
    if (index < 0) {
      return;
    }
    state.list.splice(index, 1);
  },
  CLEAR: (state) => {
    state.list = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
