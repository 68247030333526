<template>
  <v-footer app inset dark>
    <v-col class="text-left" cols="6">
      &copy;
      {{ new Date().getFullYear() }} —
      <strong>Wallin Srl</strong>
    </v-col>
    <v-col class="text-right" cols="6">
      Wallin Licence Manager v{{ version }} — made with
      <v-icon color="red" size="17">mdi-heart</v-icon>&nbsp;in Italy
    </v-col>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      version: process.env.VUE_APP_VERSION
    };
  }
};
</script>