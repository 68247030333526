/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
import store from "../store";
export default [
  {
    path: "*",
    meta: {
      name: "",
      requiresAuth: true,
    },
    redirect: {
      path: "/dashboard",
    },
  },
  // This  allows you to have pages apart of the app but no rendered inside the dash
  {
    path: "/login",
    meta: {
      name: "",
      requiresAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: "routes" */ `@/views/LoginHome.vue`),
    // redirect if already signed in
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/authorized"]) {
        next("/dashboard");
      } else {
        next();
      }
    },
    children: [
      {
        path: "",
        component: () => import(`@/components/Login.vue`),
      },
    ],
  },
  {
    name: "loading",
    path: "/loading",
    meta: {
      name: "Loading",
      requiresAuth: false,
    },
    component: () => import(`@/views/InitialLoading.vue`),
    props: true,
  },
  // add any extra routes that you want rendered in the dashboard as a child below. Change toolbar names here
  {
    path: "/dashboard",
    meta: {
      name: "Dashboard View",
      requiresAuth: true,
    },
    component: () => import(`@/views/DashboardView.vue`),
    children: [
      {
        path: "",
        name: "Dashboard",
        component: () => import(`@/components/Dashboard.vue`),
      },
      {
        path: "licences/:userId?",
        meta: {
          name: "Licences",
          requiresAuth: true,
        },
        component: () => import(`@/components/LicencesTable.vue`),
      },
      {
        path: "statistics/renewals",
        meta: {
          name: "Renewals",
          requiresAuth: true,
        },
        component: () => import(`@/components/Renewals.vue`),
      },
      {
        path: "statistics/licences/notrenewals/monthly",
        meta: {
          name: "MonthlyNotRenewalsPerLicence",
          requiresAuth: true,
        },
        component: () =>
          import(`@/components/MonthlyNotRenewalsPerLicence.vue`),
      },
      {
        path: "statistics/users/notrenewals/monthly",
        meta: {
          name: "MonthlyNotRenewals",
          requiresAuth: true,
        },
        component: () => import(`@/components/MonthlyNotRenewals.vue`),
      },
      {
        path: "statistics/activations",
        meta: {
          name: "Activations",
          requiresAuth: true,
        },
        component: () => import(`@/components/Activations.vue`),
      },
      {
        path: "statistics/summarytypes/:type?/:mode?",
        meta: {
          name: "LicencesTypes",
          requiresAuth: true,
        },
        component: () => import(`@/components/LicencesTypesTable.vue`),
      },
      {
        path: "users",
        meta: {
          name: "Users",
          requiresAuth: true,
        },
        component: () => import(`@/components/UsersTable.vue`),
      },
      {
        path: "resellers",
        meta: {
          name: "Resellers",
          requiresAuth: true,
        },
        component: () => import(`@/components/ResellersTable.vue`),
      },
      {
        path: "orders",
        meta: {
          name: "Orders",
          requiresAuth: true,
        },
        component: () => import(`@/components/OrdersTable.vue`),
      },
    ],
  },
];
