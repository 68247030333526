<template>
  <v-main>
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" lg="2" md="4" sm="6">
          <v-img
            :src="logo"
            height="97"
            aspect-ratio="1"
            contain
            class="ma-4"
          ></v-img>
          <p class="headline text-center">Welcome to Wallin Licence Manager</p>
          <v-form>
            <v-text-field
              ref="username"
              v-model="username"
              :rules="[() => !!username || 'This field is required']"
              prepend-icon="mdi-account"
              placeholder="Email"
              required
            />
            <v-text-field
              ref="password"
              v-model="password"
              :rules="[() => !!password || 'This field is required']"
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              :type="showPassword ? 'text' : 'password'"
              prepend-icon="mdi-lock"
              placeholder="*********"
              required
              @keydown.enter="login"
              @click:append="showPassword = !showPassword"
            />
          </v-form>
          <v-btn
            :loading="loading"
            depressed
            class="mt-4"
            block
            color="primary"
            @click="login"
            >Login</v-btn
          >
          <p class="caption text-center mt-4">
            Wallin Licence Manager © {{ new Date().getFullYear() }}
          </p>
          <v-snackbar v-model="snackbar" :color="color" :top="true">
            {{ errorMessages }}
            <v-btn dark text @click="snackbar = false">Close</v-btn>
          </v-snackbar>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data: function() {
    return {
      logo: require("@/assets/wallin-logo.png"),
      username: "",
      password: "",
      errorMessages: "Incorrect login info",
      snackbar: false,
      color: "general",
      showPassword: false,
      loading: false,
    };
  },

  // Sends action to Vuex that will log you in and redirect to the dash otherwise, error
  methods: {
    login: function() {
      this.loading = true;
      let username = this.username;
      let password = this.password;
      this.$store
        .dispatch("auth/login", { username, password })
        .then(() => this.$router.push("/loading"))
        .catch(() => {
          this.snackbar = true;
        })
        .finally(() => (this.loading = false));
    },
  },
  metaInfo() {
    return {
      title: "Login",
    };
  },
};
</script>
