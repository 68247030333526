<template>
  <v-app-bar app elevate-on-scroll dense dark>
    <v-app-bar-nav-icon @click.stop="toggleDrawer"></v-app-bar-nav-icon>

    <v-toolbar-title>{{title}}</v-toolbar-title>

    <div class="flex-grow-1"></div>

    <v-btn icon v-on:click="logout">
      <v-icon>mdi-power</v-icon>
    </v-btn>
  </v-app-bar>
</template>


<script>
import { mapState } from "vuex";

export default {
  data: () => ({
    responsive: false,
    responsiveInput: false
  }),
  computed: mapState({
    title: state => state.app.title,
    theme: state => state.app.theme
  }),

  mounted() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  },

  methods: {
    toggleDrawer() {
      this.$store.dispatch("app/toggleDrawer");
    },
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true;
        this.responsiveInput = false;
      } else {
        this.responsive = false;
        this.responsiveInput = true;
      }
    },
    logout: function() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.go("/");
      });
    }
  }
};
</script>

<style>
#core-toolbar a {
  text-decoration: none;
}
</style>
