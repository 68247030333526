<template>
  <v-navigation-drawer app :dark="theme === 'dark'" :value="drawer">
    <v-list>
      <v-list-item two-line>
        <v-list-item-avatar>
          <img :src="logo" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ username }}</v-list-item-title>
          <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <div v-for="(link, i) in links" :key="i" :to="link.to">
        <v-list-item
          v-if="!link.subLinks"
          :key="i"
          :to="link.to"
          class="v-list-item"
        >
          <v-list-item-icon>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          v-else
          :key="link.text"
          no-action
          :prepend-icon="link.icon"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ link.text }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="sublink in link.subLinks"
            :to="sublink.to"
            :key="sublink.text"
          >
            <v-list-item-title v-text="sublink.text" />
          </v-list-item>
        </v-list-group>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState } from "vuex";

export default {
  data: () => ({
    logo: require("@/assets/logo.png"),
    links: [
      {
        to: "/",
        icon: "mdi-view-dashboard",
        text: "Dashboard",
      },
      {
        icon: "mdi-calendar",
        text: "Statistics",
        subLinks: [
          {
            text: "Renewals",
            to: "/dashboard/statistics/renewals",
          },
          {
            text: "Activations",
            to: "/dashboard/statistics/activations",
          },
        ],
      },
      {
        to: "/dashboard/licences",
        icon: "mdi-clipboard-outline",
        text: "Licences",
      },
      {
        to: "/dashboard/orders",
        icon: "mdi-credit-card-multiple-outline",
        text: "Orders",
      },
      {
        to: "/dashboard/users",
        icon: "mdi-account",
        text: "Users",
      },
      {
        to: "/dashboard/resellers",
        icon: "mdi-account-cash",
        text: "Resellers",
      },
    ],
    responsive: false,
  }),
  computed: mapState({
    username: (state) => state.auth.username,
    email: (state) => state.auth.email,
    drawer: (state) => state.app.drawer,
    theme: (state) => state.app.theme,
  }),
  mounted() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  },
  methods: {
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    },
  },
};
</script>
