import axios from 'axios'

export default {
	summary: () => {
		return axios.get("/api/Statistics/Summary");
	},
	renewals: (year, userId) => {
		var params = "year=" + year;
		if (userId !== undefined) {
			params += "&userId=" + userId;
		}
		return axios.get("/api/Statistics/Renewals?" + params);
	},
	activations: (year, userId) => {
		var params = "year=" + year;
		if (userId !== undefined) {
			params += "&userId=" + userId;
		}
		return axios.get("/api/Statistics/Activations?" + params);
	},
	summaryType: (type) => {
		var params = type;
		return axios.get("/api/Statistics/Summary/Licences/" + params);
	}
}