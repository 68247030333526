var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}]},[_c('v-col',[_c('snackbar-error'),_c('confirm-dialog',{ref:"confirm"}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.orders,"items-per-page":10,"search":_vm.search,"custom-filter":_vm.customFilter,"expanded":_vm.expanded,"show-select":"","fixed-header":"","height":_vm.getContentHeight,"show-expand":"","dark":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","dark":""}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected.length > 0),expression:"selected.length > 0"}],staticClass:"ml-2",attrs:{"color":"accent","dark":""},on:{"click":function($event){return _vm.deleteOrders()}}},[_vm._v("Delete")]),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Search","single-line":"","hide-details":"","clearable":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-toolbar',{attrs:{"flat":"","dark":""}},[_c('v-menu',{ref:"filterDatesRange",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formattedDatesRange,"label":"Select range","prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menus.filterDatesRange),callback:function ($$v) {_vm.$set(_vm.menus, "filterDatesRange", $$v)},expression:"menus.filterDatesRange"}},[_c('v-date-picker',{attrs:{"range":"","locale":"it","first-day-of-week":1},model:{value:(_vm.filterDatesRange),callback:function ($$v) {_vm.filterDatesRange=$$v},expression:"filterDatesRange"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menus.filterDatesRange = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.loadOrders}},[_vm._v(" OK ")])],1)],1),_c('v-spacer'),_c('v-select',{attrs:{"items":_vm.filterStatuses,"label":"Status"},model:{value:(_vm.filterStatus),callback:function ($$v) {_vm.filterStatus=$$v},expression:"filterStatus"}})],1)]},proxy:true},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.createdAt))+" ")]}},{key:"item.orderStatus",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("orderStatus")(item.orderStatus))+" ")]}},{key:"item.userId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.users[item.userId].name)+" ")]}},{key:"item.resellerId",fn:function(ref){
var item = ref.item;
return [(item.resellerId)?_c('span',[_vm._v(_vm._s(_vm.resellers[item.resellerId].company))]):_vm._e()]}},{key:"item.attachmentUrl",fn:function(ref){
var item = ref.item;
return [(item.attachmentUrl)?_c('div',[_c('a',{attrs:{"href":item.attachmentUrl,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-attachment")])],1)]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.orderStatus === 0)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.approve(item)}}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-bold")])],1):_vm._e(),(item.orderStatus === 0)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.reject(item)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-cancel")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openDetails(item)}}},[_c('v-icon',[_vm._v("mdi-information")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('p',{staticClass:"mt-2"},[_vm._v("Order Id: "+_vm._s(item.id))])])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-dialog',{attrs:{"dark":"","width":"70%"},model:{value:(_vm.dialogDetails.open),callback:function ($$v) {_vm.$set(_vm.dialogDetails, "open", $$v)},expression:"dialogDetails.open"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Details of "+_vm._s(_vm.dialogDetails.id))])]),_c('v-card-text',{staticClass:"pa-0"},[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.activationDetails.length > 0),expression:"activationDetails.length > 0"}]},[_c('v-col',[_c('h3',{staticClass:"primary--text"},[_vm._v("New activations")]),_c('v-data-table',{ref:"activationDetailsDT",attrs:{"headers":_vm.activationHeaders,"items":_vm.activationDetails,"loading":_vm.dialogDetails.loading,"loading-text":"Loading order activation details","disable-filtering":"","disable-pagination":"","disable-sort":"","hide-default-footer":"","dense":"","height":"30vh","dark":""},scopedSlots:_vm._u([{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(item.startDate)))]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(item.endDate)))]}},{key:"item.licenceType",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("licenceType")(item.licenceType)))]}},{key:"item.notForResale",fn:function(ref){
var item = ref.item;
return [(item.notForResale)?_c('div',[_c('v-icon',[_vm._v("mdi-check")])],1):_vm._e()]}},{key:"item.trial",fn:function(ref){
var item = ref.item;
return [(item.trial)?_c('div',[_c('v-icon',[_vm._v("mdi-check")])],1):_vm._e()]}}],null,true)})],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.renewalDetails.length > 0),expression:"renewalDetails.length > 0"}]},[_c('v-col',[_c('h3',{staticClass:"primary--text"},[_vm._v("Renewals")]),_c('v-data-table',{ref:"renewalDetailsDT",attrs:{"headers":_vm.renewalHeaders,"items":_vm.renewalDetails,"loading":_vm.dialogDetails.loading,"loading-text":"Loading order renewal details","disable-filtering":"","disable-pagination":"","disable-sort":"","hide-default-footer":"","dense":"","height":"30vh","dark":""},scopedSlots:_vm._u([{key:"item.renewalEndDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(item.renewalEndDate)))]}},{key:"item.licenceType",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("licenceType")(item.licenceType)))]}}],null,true)})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialogDetails.open = false}}},[_vm._v("Close")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }