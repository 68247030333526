import Vue from "vue";
import Vuex from "vuex";
import app from "./modules/app";
import auth from "./modules/auth";
import licences from "./modules/licences";
import orders from "./modules/orders";
import users from "./modules/users";
import resellers from "./modules/resellers";
import statistics from "./modules/statistics";
import VuexPersist from "vuex-persist";
import Cookies from "js-cookie";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const vuexCookie = new VuexPersist({
  restoreState: function(key) {
    return Cookies.getJSON(key);
  },
  saveState: (key, state) =>
    Cookies.set(key, state, {
      expires: 3,
    }),
  modules: ["auth"],
  filter: function(mutation) {
    return (
      mutation.type == "auth/auth_success" || mutation.type == "auth/logout"
    );
  },
});

export default new Vuex.Store({
  modules: {
    app,
    auth,
    licences,
    orders,
    users,
    resellers,
    statistics,
  },
  strict: debug,
  plugins: [vuexCookie.plugin],
});
