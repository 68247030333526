import axios from 'axios'

export default {
	login: (details) => {
		return new Promise((resolve, reject) => {
			const params = new URLSearchParams();
			params.append('grant_type', 'password');
			params.append('username', details.username);
			params.append('password', details.password);
			axios({
					url: '/Token',
					method: 'post',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded'
					},
					data: params,
					withCredentials: true,
				})
				.then(loginResponse => {
					const token = loginResponse.data.access_token;

					axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
					axios.get('/api/Account/UserInfo').then(infoResponse => {
						const id = infoResponse.data.Id;
						const username = infoResponse.data.Name;
						const email = infoResponse.data.Email;
						resolve({
							token,
							id,
							username,
							email
						});

					}).catch(error => {
						axios.defaults.headers.common['Authorization'] = '';
						reject(error);
					});

				})
				.catch(error => {
					axios.defaults.headers.common['Authorization'] = '';
					reject(error);
				});
		})
	},
	logout: () => {
		delete axios.defaults.headers.common['Authorization'];
	}
}