<template>
  <v-row v-resize="onResize">
    <v-col>
      <snackbar-error></snackbar-error>
      <v-data-table
        :headers="headers"
        :items="getSortedUsers()"
        :items-per-page="10"
        :search="search"
        @click:row="onRowClick"
        fixed-header
        :height="getContentHeight"
        show-expand
        dark
      >
        <template v-slot:top>
          <v-toolbar flat dark>
            <!-- add dialog -->
            <v-dialog v-model="dialog.add" max-width="500px">
              <template v-slot:activator="{}">
                <v-btn color="primary" dark @click="openAdd">Add</v-btn>
              </template>
              <v-card>
                <v-card-title class="justify-center pb-sm-0">
                  <span class="headline">Add user</span>
                </v-card-title>

                <v-card-text class="pb-md-0 pb-sm-0">
                  <v-container>
                    <v-row>
                      <v-col cols="6" class="pb-md-0 pt-md-0 pb-sm-0 pt-sm-0">
                        <v-text-field
                          v-model="addItem.name"
                          label="Name"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" class="pb-md-0 pt-md-0 pb-sm-0 pt-sm-0">
                        <v-text-field
                          v-model="addItem.email"
                          label="Email"
                          :rules="[rules.required, rules.email]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" sm="6" md="6" class="pb-md-0 pt-md-0">
                        <v-select
                          :rules="[rules.required]"
                          v-model="addItem.platformType"
                          :items="platformTypes"
                          item-text="name"
                          item-value="model"
                          label="Platform type"
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="accent" text @click="closeAdd">Cancel</v-btn>
                  <v-btn color="primary" text @click="save">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-spacer />
            <v-btn color="primary" class="mr-6" @click="reloadTable"
              >Reload</v-btn
            >
            <v-text-field
              v-model="search"
              label="Search"
              clearable
              single-line
              hide-details
              append-icon="mdi-magnify"
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:[`item.platformId`]="{ item }">{{
          getPlatform(item.platformId) | platform
        }}</template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">API KEY: {{ item.apiKey.apiKey }}</td>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import sortBy from "lodash/sortBy";
import costants from "../utils/costants";
import find from "lodash/find";
import tableHeightMixin from "@/mixins/tableHeightMixin";

export default {
  mixins: [tableHeightMixin],
  data() {
    return {
      headers: [
        // {
        //   text: 'Dessert (100g serving)',
        //   align: 'left',
        //   sortable: false,
        //   value: 'name',
        // },
        // { text: "Id", value: "id", width: 0 },
        { text: "Name", value: "name" },
        // { text: "Customer", value: "customerName" },
        { text: "Email", value: "email" },
        { text: "Id", value: "id" },
        { text: "Platform", value: "platformId" },
      ],
      search: null,
      dialog: {
        add: false,
      },
      addItem: {
        email: null,
        name: null,
        platformType: null,
      },
      emailPattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = this.emailPattern;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  computed: {
    ...mapState({
      users: (state) => state.users.list,
      platforms: (state) => state.users.platforms,
    }),
    platformTypes() {
      return sortBy(costants.Platforms, "name");
    },
  },
  methods: {
    // customFilter: (value, search, item) => {},
    onRowClick: function(value) {
      this.$store.dispatch("licences/clear");
      this.$router.push("/dashboard/licences/" + value.id);
    },
    getSortedUsers() {
      if (this.user !== null) {
        return sortBy(this.users, "name");
      }
      return this.users;
    },
    getPlatform(id) {
      var ret = find(this.platforms, { id: id });
      //merged
      return ret !== undefined ? ret.platformType : null;
    },
    reloadTable() {
      this.$store.dispatch("users/load");
    },
    openAdd() {
      this.addItem.name = null;
      this.addItem.email = null;
      this.addItem.platformType = null;
      this.dialog.add = true;
    },
    closeAdd() {
      this.dialog.add = false;
    },
    save() {
      this.$store.dispatch("users/add", this.addItem);
      this.closeAdd();
    },
  },

  mounted() {
    let title = "Users";
    this.$store.dispatch("app/updateTitle", title);
  },
};
</script>
