import costants from "../utils/costants";
import util from "../utils/helpers";

export default [
  {
    name: "date",
    execute: util.formatDate,
  },
  {
    name: "licenceType",
    execute: (value) => {
      if (value === undefined || value === null) {
        return null;
      }
      return costants.LicencesTypes[value]["text"];
    },
  },
  {
    name: "orderStatus",
    execute: (value) => {
      if (value === undefined || value === null) {
        return null;
      }
      return costants.OrdersStatus[value]["text"];
    },
  },
  {
    name: "platform",
    execute: (value) => {
      if (value === undefined || value === null) {
        return null;
      }
      return costants.Platforms[value]["name"];
    },
  },
];
