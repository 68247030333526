import AuthService from '../../services/AuthService'

// initial state
const state = {
	authStatus: '',
	token: null,
	userId: null,
	username: null,
	email: null
}

// getters
const getters = {
	token: state => {
		return state.token;
	},
	authorized: state => {
		return !!state.token;
	},
	userId: state => {
		return state.userId;
	},
	username: state => {
		return state.username;
	},
	email: state => {
		return state.email;
	}
}

// actions
const actions = {
	login: ({
		commit
	}, userData) => {
		return new Promise((resolve, reject) => {
			commit('auth_request');
			AuthService.login(userData)
				.then(result => {
					// mutation to change state properties to the values passed along
					commit('auth_success', result)
					resolve(result)
				})
				.catch(err => {
					commit('auth_error')
					localStorage.removeItem('token')
					reject(err)
				})
		})
	},
	logout({
		commit
	}) {
		return new Promise((resolve) => {
			commit('logout')
			localStorage.removeItem('token')
			AuthService.logout()
			resolve()
		})
	},
}

// mutations
const mutations = {
	auth_request: (state) => {
		state.authStatus = 'loading'
	},
	auth_success: (state, info) => {
		state.authStatus = 'success'
		state.token = info.token
		state.userId = info.id
		state.username = info.username
		state.email = info.email
	},
	auth_error: (state) => {
		state.authStatus = 'error'
	},
	logout: (state) => {
		state.authStatus = null
		state.token = null
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}