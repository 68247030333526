<template>
  <v-row>
    <v-col>
      <v-snackbar
        v-model="errorLoading"
        color="error"
        :top="true"
      >An error occurred while loading data</v-snackbar>
      <v-snackbar
        v-model="errorOperation"
        color="error"
        :top="true"
      >An error occurred during the server communication</v-snackbar>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      errorLoading: state => state.app.errorLoading,
      errorOperation: state => state.app.errorOperation
    })
  }
};
</script>
