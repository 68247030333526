import StatisticService from "../../services/StatisticService";
import utils from "../../utils/helpers";
import moment from "moment";

// initial state
const state = {
  summary: [],
  summaryTypes: [],
  loading: false,
  renewals: null,
  activations: null,
  year: moment().year(),
};

// getters
const getters = {
  summary: (state) => {
    return state.summary;
  },
  summaryTypes: (state) => {
    return state.summaryTypes;
  },
  isLoading: (state) => {
    return state.loading;
  },
  renewals: (state) => {
    if (state.renewals == null) {
      return null;
    }

    var months = Object.keys(state.renewals);
    var ret = {};
    months.forEach((month) => {
      ret[month] = state.renewals[month].filter(
        (licence) =>
          !licence.trial &&
          !licence.nfr &&
          moment(licence.endDate).diff(moment(licence.startDate), "days") > 30
      );
    });
    return ret;
  },
  activations: (state) => {
    if (state.activations == null) {
      return null;
    }

    var months = Object.keys(state.activations);
    var ret = {};
    months.forEach((month) => {
      ret[month] = state.activations[month].filter(
        (licence) =>
          !licence.trial &&
          !licence.nfr &&
          moment(licence.endDate).diff(moment(licence.startDate), "days") > 30
      );
    });
    return ret;
  },
  year: (state) => {
    return state.year;
  },
};

// actions
const actions = {
  summary: ({ commit }) => {
    commit("setLoading", true);
    StatisticService.summary()
      .then((result) => {
        var items = result.data.map((l) => {
          return utils.toCamelCase(l);
        });
        commit("setSummary", items);
        commit("app/setErrorLoading", false, {
          root: true,
        });
      })
      .catch(() => {
        commit("setSummary", []);
        commit("app/setErrorLoading", true, {
          root: true,
        });
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  renewals: ({ commit }, year, userId) => {
    commit("setLoading", true);
    commit("setYear", year);
    StatisticService.renewals(year, userId)
      .then((response) => {
        var result = utils.toCamelCase(response.data);
        commit("setRenewals", result);
        commit("app/setErrorOperation", false, {
          root: true,
        });
      })
      .catch(() => {
        commit("app/setErrorOperation", true, {
          root: true,
        });
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  activations: ({ commit }, year, userId) => {
    commit("setYear", year);
    commit("setLoading", true);
    StatisticService.activations(year, userId)
      .then((response) => {
        var result = utils.toCamelCase(response.data);
        commit("setActivations", result);
        commit("app/setErrorOperation", false, {
          root: true,
        });
      })
      .catch(() => {
        commit("app/setErrorOperation", true, {
          root: true,
        });
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  summaryTypes: ({ commit }, type) => {
    commit("setLoading", true);
    StatisticService.summaryType(type)
      .then((response) => {
        var result = Object.keys(response.data).map(function(key) {
          response.data[key].Id = key;
          return utils.toCamelCase(response.data[key]);
        });
        commit("setSummaryTypes", result);
        commit("app/setErrorLoading", false, {
          root: true,
        });
      })
      .catch(() => {
        commit("setSummaryTypes", []);
        commit("app/setErrorLoading", true, {
          root: true,
        });
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
};

// mutations
const mutations = {
  setSummary(state, summary) {
    state.summary = summary;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setRenewals(state, renewals) {
    state.renewals = renewals;
  },
  setActivations(state, activations) {
    state.activations = activations;
  },
  setYear(state, year) {
    state.year = year;
  },
  setSummaryTypes(state, summaryTypes) {
    state.summaryTypes = summaryTypes;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
