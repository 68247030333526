import Vue from "vue";

// Components
import "./components";

// Plugins
// import "./plugins";

// Sync router with store
import { sync } from "vuex-router-sync";

import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "@/router";
import store from "@/store";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";
// import 'material-design-icons-iconfont/dist/material-design-icons.css'
import axios from "axios";

import moment from "moment";

import filters from "./filters";

Vue.prototype.$http = axios;
// Sets the default url used by all of this axios instance's requests
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers.get["Accept"] = "application/json";

// Sync store with router
sync(store, router);

moment.locale(navigator.language);

filters.forEach((f) => {
  Vue.filter(f.name, f.execute);
});

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
