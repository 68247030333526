<template>
  <v-row>
    <v-col>
      <snackbar-error></snackbar-error>
      <v-data-table
        ref="licencesDT"
        :headers="licencesHeaders"
        :items="getSortedLicences()"
        :items-per-page="10"
        :search="search"
        :loading="loading"
        :loading-text="loadingText"
        :sort-by="sortField"
        sort-desc
        fixed-header
        @click:row="onRowClick"
        @update:sort-by="setSortField"
        @update:sort-desc="setSortDesc"
        height="61vh"
        dark
      >
        <template v-slot:top>
          <v-toolbar flat dark>
            <v-btn color="primary">
              <download-csv separator-excel :data="getExportLicences()"
                >Export</download-csv
              >
            </v-btn>
            <v-spacer />
            <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details
              clearable
              append-icon="mdi-magnify"
            ></v-text-field>
          </v-toolbar>
        </template>

        <template v-slot:[`item.Name`]="{ item }">{{ item.name }}</template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import sortBy from "lodash/sortBy";
import reverse from "lodash/reverse";
import costants from "../utils/costants";

export default {
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Total", value: "total" },
        { text: "NFR", value: "notForResale" },
        { text: "Trial", value: "trial" },
        { text: "Mirror", value: "mirror" },
      ],
      sortField: "total",
      sortDesc: true,
      search: null,
      loadingText: "Loading...",
    };
  },
  computed: {
    ...mapState({
      licences: (state) => state.statistics.summaryTypes,
      loading: (state) => state.statistics.loading,
      errorLoading: (state) => state.app.errorLoading,
      errorOperation: (state) => state.app.errorOperation,
    }),
    licencesHeaders() {
      return this.headers;
    },
  },
  methods: {
    onRowClick: function(value) {
      this.$router.push("/dashboard/licences/" + value.id);
    },
    getSortedLicences() {
      return this.licences;
    },
    getExportLicences() {
      var data = sortBy(this.licences, this.sortField);
      if (this.sortDesc) {
        data = reverse(data);
      }
      return data;
    },
    setSortField(mode) {
      this.sortField = mode;
    },
    setSortDesc(mode) {
      this.sortDesc = mode;
    },
  },
  mounted() {
    this.type = this.$store.state.route.params.type;
    this.sortField = this.$store.state.route.params.mode || "total";
    this.$store.dispatch("statistics/summaryTypes", this.type);

    let title = "Licences of type " + costants.LicencesTypes[this.type]["text"];
    this.$store.dispatch("app/updateTitle", title);
  },
};
</script>
