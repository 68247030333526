export default {
  LicencesTypes: [
    {
      value: 0,
      text: "Wallin ONE",
      color: "#F18B2A",
    },
    {
      value: 1,
      text: "BrightSign",
      color: "#37236A",
    },
    {
      value: 2,
      text: "BrightSign Pro",
      color: "#37236A",
    },
    {
      value: 3,
      text: "PADS4",
      color: "#00A19B",
    },
    {
      value: 4,
      text: "WallSign",
      color: "#D6006F",
    },
    {
      value: 5,
      text: "SyncPool",
      color: "#035B96",
    },
    {
      value: 6,
      text: "ArtWall",
      color: "#FE8A71",
    },
    {
      value: 7,
      text: "WallSign Pro",
      color: "#D6006F",
    },
    {
      value: 8,
      text: "Queue Manager",
      color: "#F6CD61",
    },
    {
      value: 9,
      text: "Activity Logger",
      color: "#0E9AA7",
    },
    {
      value: 10,
      text: "WallNet Mura",
      color: "#6F1BA7 ",
    },
    {
      value: 11,
      text: "WallNet Player",
      color: "#6F1BA7 ",
    },
    {
      value: 12,
      text: "WallNet Maevex",
      color: "#6F1BA7 ",
    },
    {
      value: 13,
      text: "WallNet Server",
      color: "#6F1BA7 ",
    },
    {
      value: 14,
      text: "WallNet Server Pro",
      color: "#6F1BA7 ",
    },
    {
      value: 15,
      text: "WallNet Mura IPXO",
      color: "#6F1BA7 ",
    },
    {
      value: 16,
      text: "WallNet LDAP",
      color: "#6F1BA7 ",
    },
    {
      value: 17,
      text: "WallNet Player SOC",
      color: "#6F1BA7 ",
    },
    {
      value: 18,
      text: "BrightSign Plus",
      color: "#37236A ",
    },
    {
      value: 19,
      text: "WallNet API",
      color: "#6F1BA7 ",
    },
    {
      value: 20,
      text: "WallNet Workstation",
      color: "#6F1BA7 ",
    },
    {
      value: 21,
      text: "WallSign EPaper",
      color: "#D6006F",
    },
  ],
  Platforms: [
    {
      model: 0,
      name: "WallSign",
    },
    {
      model: 1,
      name: "Wallin ONE",
    },
    {
      model: 2,
      name: "Force (22HBG)",
    },
    {
      model: 3,
      name: "SmartiKette (Promelit)",
    },
    {
      model: 4,
      name: "DrPlus (Gruppo Marcucci)",
    },
    {
      model: 5,
      name: "WallNet",
    },
  ],
  OrdersTypes: [
    {
      value: 0,
      text: "Activation",
    },
    {
      value: 1,
      text: "Renewal",
    },
  ],
  OrdersStatus: [
    {
      value: 0,
      text: "Pending",
    },
    {
      value: 1,
      text: "Approved",
    },
    {
      value: 2,
      text: "Rejected",
    },
    {
      value: 3,
      text: "Cancelled",
    },
  ],
};
