import axios from "axios";

export default {
  getList: (userId) => {
    return axios.get("/api/Licences/List/" + userId);
  },
  get: (id) => {
    return axios.get("/api/Licences/" + id);
  },
  details: (id) => {
    return axios.get("/api/Licences/Details/" + id);
  },
  add: (licence) => {
    return axios.post("/api/Licences/", licence);
  },
  edit: (licence) => {
    return axios.put("/api/Licences/", licence);
  },
  editMulti: (params) => {
    return axios.put("/api/Licences/EditMulti", params);
  },
  delete: (params) => {
    return axios.delete(
      "/api/Licences/" + params.id + "?recursive=" + params.recursive
    );
  },
  renew: (info) => {
    return axios.post("/api/Licences/Renew", info);
  },
  move: (info) => {
    return axios.post("/api/Licences/Move", info);
  },
  changeType: (info) => {
    return axios.post("/api/Licences/ChangeType", info);
  },
  changeReseller: (info) => {
    return axios.post("/api/Licences/changeReseller", info);
  },
  controlRoomFiles: (hardwareId) => {
    return axios.get("/api/Licences/ControlRoom/Files/" + hardwareId, {
      responseType: "arraybuffer",
    });
  },
};
